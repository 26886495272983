import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { login, logout } from '../store/slices/authSlice';
import { subscribeToProperties } from '../utils/propertyUtils';

const AuthContext = createContext({
  loading: true
});

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const organizationId = useSelector((state) => state.auth.organizationId);

  // Handle auth state changes
  useEffect(() => {
    let unsubscribeProperties = null;

    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          // Only fetch user data if not already authenticated
          if (!isAuthenticated) {
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            const userData = userDoc.data();
            
            // Dispatch login action
            dispatch(login({
              uid: user.uid,
              email: user.email,
              fullName: userData?.fullName || user.displayName,
              organizationId: userData?.organizationId,
              phoneNumber: userData?.phoneNumber || user.phoneNumber,
              createdAt: userData?.createdAt || null
            }));

            // Subscribe to properties if we have an organizationId
            if (userData?.organizationId) {
              unsubscribeProperties = subscribeToProperties(userData.organizationId);
            }
          }
        } else {
          // Only dispatch logout if currently authenticated
          if (isAuthenticated) {
            dispatch(logout());
            // Cleanup property subscription
            if (unsubscribeProperties) {
              unsubscribeProperties();
              unsubscribeProperties = null;
            }
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        if (isAuthenticated) {
          dispatch(logout());
        }
      } finally {
        setLoading(false);
      }
    });

    // Cleanup subscriptions
    return () => {
      unsubscribeAuth();
      if (unsubscribeProperties) {
        unsubscribeProperties();
      }
    };
  }, [dispatch, isAuthenticated]);

  // Subscribe to properties when organizationId changes
  useEffect(() => {
    let unsubscribeProperties = null;

    if (organizationId && isAuthenticated) {
      unsubscribeProperties = subscribeToProperties(organizationId);
    }

    return () => {
      if (unsubscribeProperties) {
        unsubscribeProperties();
      }
    };
  }, [organizationId, isAuthenticated]);

  const value = {
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
