import React, { useState, useEffect, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Container,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  Stack,
  Divider,
} from '@mui/material';
import { LineChart } from '@mui/x-charts';
import { DatePicker } from '@mui/x-date-pickers';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';

function RentRenewalAnalysis() {
  const theme = useTheme();
  const auth = useSelector((state) => state.auth);
  const organizationId = auth?.organizationId;
  const { properties, loading: propertiesLoading } = useSelector((state) => state.property);
  const [payments, setPayments] = useState([]);
  const [filters, setFilters] = useState({
    propertyName: null,
    city: '',
    propertyType: '',
    startDate: dayjs(),
    endDate: dayjs().add(24, 'month')
  });
  const [rentData, setRentData] = useState({
    dates: [],
    rents: []
  });

  // Get unique cities from properties
  const cities = useMemo(() => {
    const uniqueCities = new Set(properties.map(property => property.city).filter(Boolean));
    return Array.from(uniqueCities).sort();
  }, [properties]);

  // Get unique property types from properties
  const propertyTypes = useMemo(() => {
    const uniqueTypes = new Set(properties.map(property => property.propertyType).filter(Boolean));
    return Array.from(uniqueTypes).sort();
  }, [properties]);

  // Get unique property names
  const propertyOptions = useMemo(() => {
    return properties.map(property => ({
      id: property.id,
      label: property.propertyName
    })).sort((a, b) => a.label.localeCompare(b.label));
  }, [properties]);

  // Handle filter changes
  const handleFilterChange = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Subscribe to payments when properties change
  useEffect(() => {
    if (!organizationId || !properties.length) {
      return;
    }

    const paymentSubscriptions = [];

    // Subscribe to payments collection for each property
    properties.forEach(property => {
      const paymentsQuery = collection(db, 'properties', property.id, 'payments');

      const paymentSub = onSnapshot(paymentsQuery, (paymentSnapshot) => {
        const paymentDocs = paymentSnapshot.docs.map(paymentDoc => ({
          id: paymentDoc.id,
          propertyId: property.id,
          propertyName: property.propertyName,
          city: property.city,
          propertyType: property.propertyType,
          ...paymentDoc.data()
        }));

        setPayments(currentPayments => {
          const otherPayments = currentPayments.filter(p => p.propertyId !== property.id);
          return [...otherPayments, ...paymentDocs];
        });
      });

      paymentSubscriptions.push(paymentSub);
    });

    return () => {
      paymentSubscriptions.forEach(unsub => unsub());
    };
  }, [organizationId, properties]);

  // Filter and calculate rent data
  useEffect(() => {
    try {
      // Filter payments based on criteria
      let filteredPayments = payments;

      if (filters.propertyName) {
        filteredPayments = filteredPayments.filter(payment =>
          payment.propertyName === filters.propertyName.label
        );
      }

      if (filters.city) {
        filteredPayments = filteredPayments.filter(payment =>
          payment.city === filters.city
        );
      }

      if (filters.propertyType) {
        filteredPayments = filteredPayments.filter(payment =>
          payment.propertyType === filters.propertyType
        );
      }

      // Generate monthly data points for the selected date range
      const dates = [];
      const rents = [];
      const startDate = filters.startDate.toDate();
      const endDate = filters.endDate.toDate();

      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        dates.push(new Date(currentDate));

        // Calculate total rent for this month
        const totalRent = filteredPayments.reduce((sum, payment) => {
          const paymentDate = payment.dueDate.toDate();
          if (
            paymentDate.getFullYear() === currentDate.getFullYear() &&
            paymentDate.getMonth() === currentDate.getMonth()
          ) {
            return sum + (payment.amount || 0);
          }
          return sum;
        }, 0);

        rents.push(totalRent);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      setRentData({ dates, rents });
    } catch (error) {
      console.error('Error calculating rent data:', error);
    }
  }, [filters, payments]);

  return (
    <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
          <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 1 }}>
            <Autocomplete
              disablePortal
              id="property-name"
              options={propertyOptions}
              value={filters.propertyName}
              onChange={(event, value) => handleFilterChange('propertyName', value)}
              renderInput={(params) => (
                <TextField {...params} label="Property Name" size="small" sx={{ width: 300 }} />
              )}
            />
            <FormControl sx={{ minWidth: 200 }} size="small">
              <InputLabel id="city-label">City</InputLabel>
              <Select
                labelId="city-label"
                id="city"
                value={filters.city}
                label="City"
                onChange={(e) => handleFilterChange('city', e.target.value)}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {cities.map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 200 }} size="small">
              <InputLabel id="property-type-label">Property Type</InputLabel>
              <Select
                labelId="property-type-label"
                id="property-type"
                value={filters.propertyType}
                label="Property Type"
                onChange={(e) => handleFilterChange('propertyType', e.target.value)}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {propertyTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DatePicker
              label="Start Date"
              value={filters.startDate}
              onChange={(newValue) => handleFilterChange('startDate', newValue)}
              slotProps={{
                textField: {
                  size: "small",
                  sx: { width: 200 }
                }
              }}
            />
            <DatePicker
              label="End Date"
              value={filters.endDate}
              onChange={(newValue) => handleFilterChange('endDate', newValue)}
              slotProps={{
                textField: {
                  size: "small",
                  sx: { width: 200 }
                }
              }}
            />
          </Stack>
        </Box>

        {/* Chart */}

        <Box sx={{ p: 2, width: '100%', height: 400 }}>
          <Typography variant="h6" gutterBottom>
            Rent Analysis Over Time
          </Typography>
          {rentData.dates.length > 0 ? (
            <LineChart
              xAxis={[{
                data: rentData.dates,
                scaleType: 'time',
                valueFormatter: (date) => dayjs(date).format('MMM YYYY'),
                tickLabelStyle: { fontSize: 12 }
              }]}
              yAxis={[{
                tickLabelStyle: { fontSize: 12 },
                valueFormatter: (value) => `₹${value.toLocaleString('en-IN')}`,
                tickMinStep: 10000
              }]}
              series={[{
                data: rentData.rents,
                label: 'Total Rent',
                valueFormatter: (value) => `₹${value.toLocaleString('en-IN')}`,
                color: theme.palette.primary.main
              }]}
              height={350}
              margin={{ left: 70, right: 20, top: 20, bottom: 30 }}
              slotProps={{
                legend: {
                  direction: 'row',
                  position: { vertical: 'top', horizontal: 'middle' },
                  padding: 0,
                  labelStyle: { fontSize: 14 }
                }
              }}
            />
          ) : (
            <Box
              sx={{
                height: 350,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'grey.50',
                borderRadius: 1
              }}
            >
              <Typography variant="body1" color="text.secondary">
                No data available for the selected filters
              </Typography>
            </Box>
          )}
        </Box>
    </Paper>
  );
}

export default RentRenewalAnalysis;
