import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton as MuiIconButton,
  CircularProgress,
  Toolbar,
} from '@mui/material';
import {
  CloudUpload as UploadIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  Description as DocumentIcon,
  Visibility as PreviewIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { collection, query, onSnapshot, where, doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { db, storage, auth } from '../firebase';
import { useSelector } from 'react-redux';

// Document types
const documentTypes = [
  'Agreement',
  'Insurance Document',
  'Tax Document',
  'Rent Invoice',
  'Other Invoice',
  'Operator NOC',
  'Landlord NOC',
  'Utility Bill',
  'Other',
];

// Upload Document Dialog Component
const UploadDocumentDialog = ({ open, onClose, properties }) => {
  const [selectedProperty, setSelectedProperty] = useState('');
  const [selectedDocType, setSelectedDocType] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handlePropertyChange = (event) => {
    setSelectedProperty(event.target.value);
  };

  const handleDocTypeChange = (event) => {
    setSelectedDocType(event.target.value);
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile || !selectedProperty || !selectedDocType) return;

    try {
      setUploading(true);
      
      // 1. Upload file to Firebase Storage
      const storageRef = ref(storage, `documents/${selectedProperty}/${selectedFile.name}`);
      await uploadBytes(storageRef, selectedFile);
      const storageUrl = await getDownloadURL(storageRef);

      // 2. Update Firestore document
      const propertyRef = doc(db, 'properties', selectedProperty);
      
      // Get current property document
      const propertyDoc = await getDoc(propertyRef);
      if (!propertyDoc.exists()) {
        throw new Error('Property not found');
      }

      // Prepare new document entry
      const newDocument = {
        fileName: selectedFile.name,
        documentType: selectedDocType,
        storageUrl,
        importedAt: new Date().toISOString()
      };

      // Update documents array in property
      await updateDoc(propertyRef, {
        documents: arrayUnion(newDocument)
      });

      // Reset form
      setSelectedFile(null);
      setSelectedProperty('');
      setSelectedDocType('');
      onClose();
    } catch (error) {
      console.error('Error uploading document:', error);
      // TODO: Show error message to user
    } finally {
      setUploading(false);
    }
  };

  const handleClose = () => {
    setSelectedFile(null);
    setSelectedProperty('');
    setSelectedDocType('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Upload Document</Typography>
          <MuiIconButton edge="end" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </MuiIconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Property</InputLabel>
              <Select
                value={selectedProperty}
                label="Property"
                onChange={handlePropertyChange}
              >
                {properties.map((property) => (
                  <MenuItem key={property.id} value={property.id}>
                    {property.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Document Type</InputLabel>
              <Select
                value={selectedDocType}
                label="Document Type"
                onChange={handleDocTypeChange}
              >
                {documentTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 3,
                border: '2px dashed #ccc',
                borderRadius: 1,
                backgroundColor: '#fafafa',
                cursor: 'pointer',
              }}
              component="label"
            >
              <input
                type="file"
                accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                hidden
                onChange={handleFileSelect}
              />
              <UploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 1 }} />
              <Typography variant="body1" gutterBottom>
                Drop file here or click to browse
              </Typography>
              {selectedFile && (
                <Typography variant="body2" color="textSecondary">
                  Selected: {selectedFile.name}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleUpload}
          variant="contained"
          disabled={!selectedFile || !selectedProperty || !selectedDocType || uploading}
          startIcon={uploading ? <CircularProgress size={20} /> : null}
        >
          {uploading ? 'Uploading...' : 'Upload'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function DocumentVault() {
  const [documents, setDocuments] = useState([]);
  const [properties, setProperties] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewDocument, setPreviewDocument] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [searchProperty, setSearchProperty] = useState('');
  const [searchDocType, setSearchDocType] = useState('');
  const { organizationId } = useSelector((state) => state.auth);

  // Filter documents based on search criteria
  const filteredDocuments = useMemo(() => {
    return documents.filter(doc => {
      const matchesProperty = !searchProperty || doc.propertyName.toLowerCase().includes(searchProperty.toLowerCase());
      const matchesDocType = !searchDocType || doc.documentType === searchDocType;
      return matchesProperty && matchesDocType;
    });
  }, [documents, searchProperty, searchDocType]);

  const handleSearchPropertyChange = (event) => {
    setSearchProperty(event.target.value);
  };

  const handleSearchDocTypeChange = (event) => {
    setSearchDocType(event.target.value);
  };

  const clearSearch = () => {
    setSearchProperty('');
    setSearchDocType('');
  };

  useEffect(() => {
    if (!organizationId) return;

    // Listen for property changes
    const propertiesQuery = query(
      collection(db, 'properties'),
      where('organizationId', '==', organizationId)
    );

    const unsubscribe = onSnapshot(propertiesQuery, (snapshot) => {
      const propertyList = [];
      const documentList = [];

      snapshot.forEach((doc) => {
        const property = { id: doc.id, ...doc.data() };
        propertyList.push({
          id: property.id,
          name: property.propertyName
        });

        // Extract documents from each property
        if (property.documents && Array.isArray(property.documents)) {
          property.documents.forEach(document => {
            documentList.push({
              ...document,
              id: `${property.id}_${document.fileName}`,
              propertyName: property.propertyName,
              propertyId: property.id
            });
          });
        }
      });

      setProperties(propertyList);
      setDocuments(documentList);
    });

    return () => unsubscribe();
  }, [organizationId]);

  const handleDownload = async (storageUrl) => {
    try {
      setLoading(true);
      const storageRef = ref(storage, storageUrl);
      const url = await getDownloadURL(storageRef);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePreview = async (document) => {
    try {
      setLoading(true);
      setPreviewDocument(document);
      setPreviewOpen(true);
      
      // Get authenticated URL
      const storageRef = ref(storage, document.storageUrl);
      const url = await getDownloadURL(storageRef);
      setPreviewUrl(url);
    } catch (error) {
      console.error('Error getting preview URL:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
    setPreviewDocument(null);
    setPreviewUrl('');
  };

  const isImageFile = (fileName) => {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
    return imageExtensions.some(ext => 
      fileName.toLowerCase().endsWith(ext)
    );
  };

  const PreviewDialog = () => {
    if (!previewDocument) return null;

    const isImage = isImageFile(previewDocument.fileName);

    return (
      <Dialog 
        open={previewOpen} 
        onClose={handleClosePreview}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">{previewDocument.fileName}</Typography>
            <MuiIconButton
              edge="end"
              color="inherit"
              onClick={handleClosePreview}
              aria-label="close"
            >
              <CloseIcon />
            </MuiIconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
              <CircularProgress />
            </Box>
          ) : previewUrl ? (
            isImage ? (
              <Box
                component="img"
                src={previewUrl}
                alt={previewDocument.fileName}
                sx={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '80vh',
                  objectFit: 'contain'
                }}
              />
            ) : (
              <Box
                component="iframe"
                src={`${previewUrl}#toolbar=1`}
                sx={{
                  width: '100%',
                  height: '80vh',
                  border: 'none'
                }}
              />
            )
          ) : (
            <Typography color="error">Failed to load preview</Typography>
          )}
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Container maxWidth={false} 
    disableGutters
    sx={{ 
      height: '100%', 
      overflow: 'hidden', 
      width: '100vw',
      maxWidth: '100%',
      pb: 2,
    }}>
      <Grid container spacing={3}>
        {/* Documents List */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">Documents</Typography>
                <Button
                  variant="contained"
                  startIcon={<UploadIcon />}
                  onClick={() => setUploadDialogOpen(true)}
                >
                  Upload Document
                </Button>
              </Box>

              {/* Search Controls */}
              <Box sx={{ mb: 3, display: 'flex', gap: 2, alignItems: 'center' }}>
                <TextField
                  label="Search by Property"
                  variant="outlined"
                  size="small"
                  value={searchProperty}
                  onChange={handleSearchPropertyChange}
                  sx={{ minWidth: 200 }}
                />
                <FormControl sx={{ minWidth: 200 }} size="small">
                  <InputLabel>Document Type</InputLabel>
                  <Select
                    value={searchDocType}
                    label="Document Type"
                    onChange={handleSearchDocTypeChange}
                  >
                    <MenuItem value="">All Types</MenuItem>
                    {documentTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {(searchProperty || searchDocType) && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={clearSearch}
                    startIcon={<CloseIcon />}
                  >
                    Clear
                  </Button>
                )}
              </Box>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Document Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Property</TableCell>
                      <TableCell>Upload Date</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredDocuments.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          <Typography variant="body2" color="textSecondary">
                            No documents found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredDocuments.map((doc) => (
                        <TableRow key={doc.id}>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <DocumentIcon sx={{ mr: 1, color: 'primary.main' }} />
                              {doc.fileName}
                            </Box>
                          </TableCell>
                          <TableCell>{doc.documentType}</TableCell>
                          <TableCell>{doc.propertyName}</TableCell>
                          <TableCell>{new Date(doc.importedAt).toLocaleDateString()}</TableCell>
                          <TableCell align="right">
                            <IconButton 
                              color="primary" 
                              size="small"
                              onClick={() => handlePreview(doc)}
                              title="Preview"
                              disabled={loading}
                            >
                              <PreviewIcon />
                            </IconButton>
                            <IconButton 
                              color="primary" 
                              size="small"
                              onClick={() => handleDownload(doc.storageUrl)}
                              title="Download"
                              disabled={loading}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <PreviewDialog />
      <UploadDocumentDialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        properties={properties}
      />
    </Container>
  );
}

export default DocumentVault;
