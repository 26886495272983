import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  IconButton,
  Divider,
  Chip,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Toolbar,
  AppBar,
  Button,
  CircularProgress,
} from '@mui/material';
import {
  Search as SearchIcon,
  ErrorOutline as HighIcon,
  WarningAmber as MediumIcon,
  Info as LowIcon,
  Business as PropertyIcon,
  AttachMoney as CostIcon,
  Build as ServiceIcon,
  Description as LeaseIcon,
  FilterList as FilterIcon,
  Refresh as RefreshIcon,
  CheckCircle as DoneIcon,
  Block as IgnoredIcon,
  CalendarMonth as ScheduledIcon,
} from '@mui/icons-material';
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  Timestamp,
} from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';
import RecommendationDetails from '../components/RecommendationDetails';

const priorityConfig = {
  high: {
    icon: HighIcon,
    color: '#f44336',
    label: 'High Priority',
  },
  medium: {
    icon: MediumIcon,
    color: '#ff9800',
    label: 'Medium Priority',
  },
  low: {
    icon: LowIcon,
    color: '#4caf50',
    label: 'Low Priority',
  },
};

const typeConfig = {
  property: {
    icon: PropertyIcon,
    color: '#2196f3',
    label: 'Property',
    description: 'Property maintenance or improvement',
  },
  cost: {
    icon: CostIcon,
    color: '#2196f3',
    label: 'Cost',
    description: 'Cost optimization opportunity',
  },
  service: {
    icon: ServiceIcon,
    color: '#4caf50',
    label: 'Service',
    description: 'Service quality improvement',
  },
  lease: {
    icon: LeaseIcon,
    color: '#ff9800',
    label: 'Lease',
    description: 'Lease management action',
  },
};

const statusConfig = {
  pending: {
    label: 'Pending',
    color: 'default',
  },
  meeting_scheduled: {
    label: 'Meeting Scheduled',
    color: 'info',
    icon: ScheduledIcon,
  },
  done: {
    label: 'Done',
    color: 'success',
    icon: DoneIcon,
  },
  ignored: {
    label: 'Ignored',
    color: 'error',
    icon: IgnoredIcon,
  },
};

const Recommendations = () => {
  const [recommendations, setRecommendations] = useState([]);
  const [selectedRecommendation, setSelectedRecommendation] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [priorityFilter, setPriorityFilter] = useState('all');
  const [typeFilter, setTypeFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        setLoading(true);
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${baseURL}/api/recommendations`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch recommendations');
        }

        const data = await response.json();
        console.log('Fetched recommendations:', data);
        setRecommendations(data.data || []);
        setError(null);
      } catch (err) {
        console.error('Error fetching recommendations:', err);
        setError('Failed to load recommendations. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchRecommendations();
  }, []);

  const filteredRecommendations = recommendations.filter((rec) => {
    console.log('Filtering recommendation:', rec);
    const matchesSearch =
      rec.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      rec.description?.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesPriority = priorityFilter === 'all' || rec.priority === priorityFilter;
    const matchesType = typeFilter === 'all' || rec.type === typeFilter;
    const matchesStatus = statusFilter === 'all' || rec.status === statusFilter;

    return matchesSearch && matchesPriority && matchesType && matchesStatus;
  }).sort((a, b) => {
    const priorityOrder = { high: 0, medium: 1, low: 2 };
    const priorityA = priorityOrder[a.priority] ?? 3; // Default to lowest priority if unknown
    const priorityB = priorityOrder[b.priority] ?? 3;
    
    // Sort by priority first
    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    }
    
    // If priorities are equal, sort by creation date (newest first)
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  console.log('Filtered and sorted recommendations:', filteredRecommendations);

  const handleRecommendationClick = (recommendation) => {
    setSelectedRecommendation(recommendation);
  };

  if (error) {
    return (
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'center',
        height: 'calc(100vh - 64px)',
        gap: 2
      }}>
        <Typography color="error">{error}</Typography>
        <Button 
          variant="contained" 
          onClick={() => window.location.reload()}
        >
          Retry
        </Button>
      </Box>
    );
  }

  return (
    <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)' }}>
      {/* Left Panel - Recommendations List */}
      <Box 
        sx={{ 
          width: 400, 
          minWidth: 400, 
          maxWidth: 400,
          borderRight: 1, 
          borderColor: 'divider', 
          display: 'flex', 
          flexDirection: 'column',
          overflow: 'hidden'
        }}
      >
        {/* Filters */}
        <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
          <TextField
            fullWidth
            size="small"
            placeholder="Search recommendations..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel>Priority</InputLabel>
                <Select
                  value={priorityFilter}
                  label="Priority"
                  onChange={(e) => setPriorityFilter(e.target.value)}
                >
                  <MenuItem value="all">All Priorities</MenuItem>
                  <MenuItem value="high">High</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="low">Low</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel>Type</InputLabel>
                <Select
                  value={typeFilter}
                  label="Type"
                  onChange={(e) => setTypeFilter(e.target.value)}
                >
                  <MenuItem value="all">All Types</MenuItem>
                  <MenuItem value="property">Property</MenuItem>
                  <MenuItem value="cost">Cost</MenuItem>
                  <MenuItem value="service">Service</MenuItem>
                  <MenuItem value="lease">Lease</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel>Status</InputLabel>
                <Select
                  value={statusFilter}
                  label="Status"
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <MenuItem value="all">All Status</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="meeting_scheduled">Meeting Scheduled</MenuItem>
                  <MenuItem value="done">Done</MenuItem>
                  <MenuItem value="ignored">Ignored</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {/* Recommendations List */}
        <List sx={{ flexGrow: 1, overflowY: 'auto', position: 'relative' }}>
          {loading ? (
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center',
              height: '100%'
            }}>
              <CircularProgress />
            </Box>
          ) : filteredRecommendations.length > 0 ? (
            filteredRecommendations.map((recommendation) => {
              const PriorityIcon = priorityConfig[recommendation.priority]?.icon || MediumIcon;
              const TypeIcon = typeConfig[recommendation.type]?.icon || PropertyIcon;
              const StatusIcon = statusConfig[recommendation.status]?.icon;

              return (
                <React.Fragment key={recommendation.id}>
                  <ListItemButton
                    selected={selectedRecommendation?.id === recommendation.id}
                    onClick={() => handleRecommendationClick(recommendation)}
                    sx={{
                      '&:hover': { backgroundColor: 'action.hover' },
                      backgroundColor: selectedRecommendation?.id === recommendation.id ? 'grey.100' : 'inherit',
                      '&.Mui-selected': {
                        backgroundColor: 'grey.100',
                        '&:hover': {
                          backgroundColor: 'grey.200'
                        },
                        color: 'inherit',
                        '& .MuiListItemIcon-root': {
                          color: 'inherit'
                        },
                        '& .MuiTypography-root': {
                          color: 'inherit'
                        }
                      }
                    }}
                  >
                    <ListItemIcon>
                      <PriorityIcon sx={{ color: priorityConfig[recommendation.priority]?.color }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontWeight: !recommendation.read ? 600 : 400,
                              flex: 1,
                            }}
                          >
                            {recommendation.title}
                          </Typography>
                          {StatusIcon && (
                            <StatusIcon fontSize="small" color={statusConfig[recommendation.status]?.color} />
                          )}
                        </Box>
                      }
                      secondary={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0.5 }}>
                          <TypeIcon sx={{ fontSize: 16, color: typeConfig[recommendation.type]?.color }} />
                          <Typography variant="caption" sx={{ flex: 1 }}>
                            {recommendation.description.substring(0, 60)}...
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {moment(recommendation.createdAt).fromNow()}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItemButton>
                  <Divider component="li" />
                </React.Fragment>
              );
            })
          ) : (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 3,
              }}
            >
              <Typography variant="body1" color="text.secondary">
                No recommendations found
              </Typography>
            </Box>
          )}
        </List>
      </Box>

      {/* Right Panel - Recommendation Details */}
      <Box sx={{ 
        flexGrow: 1, 
        bgcolor: 'background.default',
        overflow: 'hidden'
      }}>
        {selectedRecommendation ? (
          <RecommendationDetails
            recommendation={selectedRecommendation}
            open={true}
            onClose={() => setSelectedRecommendation(null)}
          />
        ) : (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h6" color="text.secondary">
              Select a recommendation to view details
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
    </Paper>
  );
};

export default Recommendations;
