export const COMPLIANCE_TYPES = {
  BUILDING_SAFETY: 'Building and Fire Safety',
  HEALTH_SAFETY: 'Health and Safety',
  LEGAL_LICENSES: 'Legal and Operational Licenses',
  ACCESSIBILITY: 'Accessibility Standards',
  LABOR_LAW: 'Labor Law Compliance',
  IT_INTERNET: 'IT and Internet Compliance',
  ENVIRONMENTAL: 'Environmental Compliance',
  ENERGY_EFFICIENCY: 'Energy Efficiency',
  INSURANCE: 'Insurance Requirements',
  ZONING: 'Zoning Regulations',
  
};

export const COMPLIANCE_CHECKLIST = {
  [COMPLIANCE_TYPES.BUILDING_SAFETY]: [
    { id: 'fire_noc', label: 'Fire NOC', description: 'Fire department NOC' },
    { id: 'fire_equipment', label: 'Fire Safety Equipment', description: 'Fire extinguishers, alarms' },
    { id: 'emergency_exits', label: 'Emergency Exits', description: 'Clear emergency exits' },
    { id: 'building_stability', label: 'Building Stability', description: 'Structural stability certificate' },
    { id: 'fire_drill', label: 'Periodic Fire Drills', description: 'Conduct regular fire drills with all occupants, maintain drill logs, train staff on emergency procedures, and ensure evacuation routes are clearly marked and accessible. Minimum two drills per year required.' }
  ],
  [COMPLIANCE_TYPES.HEALTH_SAFETY]: [
    { id: 'sanitation', label: 'Sanitation and Hygiene', description: 'Clean restrooms and waste management' },
    { id: 'drinking_water', label: 'Drinking Water', description: 'Clean drinking water facilities' },
    { id: 'air_quality', label: 'Air Quality Control', description: 'Proper ventilation and air quality' }
  ],
  [COMPLIANCE_TYPES.LEGAL_LICENSES]: [
    { id: 'trade_license', label: 'Trade License', description: 'Trade license from municipal corporation' },
    { id: 'shop_act', label: 'Shops and Establishment Act', description: 'Registration under state Shop Act' },
    { id: 'gst', label: 'GST Registration', description: 'GST registration if applicable' }
  ],
  [COMPLIANCE_TYPES.ACCESSIBILITY]: [
    { id: 'ramps', label: 'Ramps', description: 'Wheelchair ramps available' },
    { id: 'elevators', label: 'Accessible Elevators', description: 'Elevators with accessibility features' },
    { id: 'restrooms', label: 'Accessible Restrooms', description: 'Disabled-friendly restrooms' }
  ],
  [COMPLIANCE_TYPES.LABOR_LAW]: [
    { id: 'employee_contracts', label: 'Employee Contracts', description: 'Proper contracts with staff' },
    { id: 'service_agreements', label: 'Service Provider Agreements', description: 'Contracts with service providers' },
    { id: 'labor_laws', label: 'Labor Law Compliance', description: 'Adherence to labor laws' }
  ],
  [COMPLIANCE_TYPES.IT_INTERNET]: [
    { id: 'data_security', label: 'Data Security', description: 'User data protection measures' },
    { id: 'isp_compliance', label: 'ISP Compliance', description: 'TRAI guideline compliance' },
    { id: 'privacy_policy', label: 'Privacy Policy', description: 'Privacy policy in place' }
  ],
  [COMPLIANCE_TYPES.ENVIRONMENTAL]: [
    {
      id: 'wastewater_management',
      label: 'Wastewater Management',
      description: 'Ensure STP installation and maintenance, reuse treated water for landscaping/cleaning, prevent groundwater contamination through proper treatment.'
    },
    {
      id: 'solid_waste_management',
      label: 'Solid Waste Management',
      description: 'Implement waste segregation at source, partner with certified recycling agencies, encourage reusable items, set up composting for biodegradable waste.'
    },
    {
      id: 'air_quality_management',
      label: 'Air Quality Management',
      description: 'Use air purifiers/plants for indoor air quality, conduct regular tests, ensure emission compliance, use low-emission materials during renovations.'
    },
    {
      id: 'rainwater_harvesting',
      label: 'Rainwater Harvesting',
      description: 'Install and maintain rainwater harvesting system, comply with local regulations, use harvested water for landscaping/non-potable uses.'
    },
    {
      id: 'noise_pollution',
      label: 'Noise Pollution Control',
      description: 'Monitor and limit equipment noise levels, use soundproofing materials to reduce noise impact on surrounding areas.'
    },
    {
      id: 'landscaping',
      label: 'Landscaping and Green Cover',
      description: 'Maintain green spaces, use native/drought-resistant plants, opt for organic alternatives to chemical pesticides/fertilizers.'
    },
    {
      id: 'hazardous_waste',
      label: 'Hazardous Waste Management',
      description: 'Safely dispose of hazardous materials through authorized agencies, provide designated collection points.'
    },
    {
      id: 'water_conservation',
      label: 'Water Conservation',
      description: 'Install water-saving fixtures, regularly check for leaks, monitor and report water usage to reduce wastage.'
    },
    {
      id: 'environmental_assessment',
      label: 'Environmental Impact Assessments',
      description: 'Conduct EIA if required by local laws, address recommendations to minimize environmental footprint.'
    }
  ],
  [COMPLIANCE_TYPES.INSURANCE]: [
    { id: 'liability_insurance', label: 'Public Liability Insurance', description: 'Insurance for accidents' },
    { id: 'property_insurance', label: 'Property Insurance', description: 'Insurance against damages' }
  ],
  [COMPLIANCE_TYPES.ZONING]: [
    { id: 'zoning_approval', label: 'Zoning Approval', description: 'Commercial/mixed-use zone approval' },
    { id: 'land_use', label: 'Land Use Compliance', description: 'Compliance with land use regulations' }
  ],
  [COMPLIANCE_TYPES.ENERGY_EFFICIENCY]: [
    {
      id: 'energy_lighting',
      label: 'Energy Efficient Lighting',
      description: 'Install energy-efficient LED or CFL lights. Use motion-sensor lighting in common areas like corridors and washrooms. Ensure natural lighting is maximized during daytime to reduce dependence on artificial lights.'
    },
    {
      id: 'energy_hvac',
      label: 'Energy Efficient HVAC',
      description: 'Use energy-efficient HVAC (Heating, Ventilation, and Air Conditioning) systems rated with a high energy star rating. Conduct regular maintenance to improve efficiency and reduce energy wastage. Install programmable thermostats to optimize heating and cooling.'
    },
    {
      id: 'renewable_energy',
      label: 'Renewable Energy',
      description: 'Install solar panels or use other renewable energy sources, if feasible. Collaborate with renewable energy providers to supplement the building\'s energy requirements.'
    },
    {
      id: 'building_insulation',
      label: 'Building Insulation',
      description: 'Use energy-efficient materials for insulation to minimize heat gain or loss. Seal windows and doors to prevent energy leakage.'
    },
    {
      id: 'water_management',
      label: 'Water Management',
      description: 'Install energy-efficient water heaters and pumps. Use sensor-based or low-flow fixtures to reduce water wastage.'
    },
    {
      id: 'energy_monitoring',
      label: 'Energy Monitoring Systems',
      description: 'Deploy smart meters to monitor and analyze energy consumption. Use data analytics to identify high-consumption areas and implement corrective measures.'
    },
    {
      id: 'energy_certifications',
      label: 'Certifications and Audits',
      description: 'Get the building certified for energy efficiency (e.g., LEED certification). Conduct regular energy audits to identify areas for improvement and ensure compliance with standards like the Energy Conservation Building Code (ECBC).'
    }
  ]
};

export const COMPLIANCE_WEIGHTS = {
  [COMPLIANCE_TYPES.BUILDING_SAFETY]: 1,
  [COMPLIANCE_TYPES.HEALTH_SAFETY]: 1,
  [COMPLIANCE_TYPES.LEGAL_LICENSES]: 1,
  [COMPLIANCE_TYPES.ACCESSIBILITY]: 1,
  [COMPLIANCE_TYPES.LABOR_LAW]: 1,
  [COMPLIANCE_TYPES.IT_INTERNET]: 1,
  [COMPLIANCE_TYPES.ENVIRONMENTAL]: 1,
  [COMPLIANCE_TYPES.INSURANCE]: 1,
  [COMPLIANCE_TYPES.ZONING]: 1,
  [COMPLIANCE_TYPES.ENERGY_EFFICIENCY]: 1
};
