import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Divider,
  Alert,
} from '@mui/material';
import { updateProfile } from '../store/slices/authSlice';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

function Account() {
  const { user, fullName, organizationName } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    fullName: fullName || '',
    organizationName: organizationName || '',
    email: user?.email || '',
  });
  const [message, setMessage] = useState({ type: '', text: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({ type: '', text: '' });

    try {
      // Update Firestore
      await updateDoc(doc(db, 'users', user.uid), {
        fullName: formData.fullName,
        organizationName: formData.organizationName,
        updatedAt: new Date().toISOString(),
      });

      // Update Redux state
      dispatch(updateProfile({
        fullName: formData.fullName,
        organizationName: formData.organizationName,
      }));

      setMessage({
        type: 'success',
        text: 'Profile updated successfully!'
      });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      setMessage({
        type: 'error',
        text: 'Failed to update profile. Please try again.'
      });
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={0} sx={{ p: 4, borderRadius: 2 }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
            My Account
          </Typography>
          <Typography variant="body2" color="text.secondary">
            View and manage your account information
          </Typography>
        </Box>

        <Divider sx={{ mb: 4 }} />

        {message.text && (
          <Alert 
            severity={message.type} 
            sx={{ mb: 3 }}
            onClose={() => setMessage({ type: '', text: '' })}
          >
            {message.text}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'grid', gap: 3 }}>
            <TextField
              fullWidth
              label="Full Name"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              disabled={!isEditing}
              required
            />

            <TextField
              fullWidth
              label="Organization Name"
              name="organizationName"
              value={formData.organizationName}
              onChange={handleChange}
              disabled={!isEditing}
              required
            />

            <TextField
              fullWidth
              label="Email"
              type="email"
              value={formData.email}
              disabled
              helperText="Email cannot be changed"
            />

            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 2 }}>
              {!isEditing ? (
                <Button
                  variant="contained"
                  onClick={() => setIsEditing(true)}
                  sx={{ minWidth: 120 }}
                >
                  Edit Profile
                </Button>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setIsEditing(false);
                      setFormData({
                        fullName: fullName || '',
                        organizationName: organizationName || '',
                        email: user?.email || '',
                      });
                    }}
                    sx={{ minWidth: 120 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ minWidth: 120 }}
                  >
                    Save Changes
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </form>
      </Paper>
    </Container>
  );
}

export default Account;
