import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // Recommendations
  showRenewalRecommendations: true,
  showSpaceOptimizations: true,
  enableAIRecommendations: true,

  // Notifications
  emailPaymentReminders: true,
  inAppReminders: true,
  emailRecommendations: true,

  // Benchmarking
  benchmarkFrequency: 'realtime',

  // Industry Reports
  showCBREReports: true,
  showIndustryReports: true,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateSettings: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateSettings } = settingsSlice.actions;
export default settingsSlice.reducer;
