/**
 * Formats a number as Indian Rupees with the ₹ symbol
 * @param {number} amount - The amount to format
 * @returns {string} Formatted amount with ₹ symbol and thousands separators
 */
export const formatIndianRupees = (amount) => {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};
