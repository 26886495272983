import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Box,
} from '@mui/material';
import {
  TrendingUp as TrendingUpIcon,
  Assessment as AssessmentIcon,
  Business as BusinessIcon,
  Description as DescriptionIcon,
} from '@mui/icons-material';

const reportCards = [
  {
    title: 'Portfolio Summary',
    description: 'Overview of your property portfolio performance',
    icon: BusinessIcon,
    path: '/portfolio-summary',
  },
  {
    title: 'Portfolio Performance',
    description: 'Detailed analysis of your portfolio metrics',
    icon: TrendingUpIcon,
    path: '/portfolio-performance',
  },
  {
    title: 'Rent Renewal Analysis',
    description: 'Analyze rent trends and upcoming renewals',
    icon: AssessmentIcon,
    path: '/reports/rent-renewal-analysis',
  },
  {
    title: 'Industry Reports',
    description: 'Market insights and industry benchmarks',
    icon: DescriptionIcon,
    path: '/industry-reports',
  },
];

function Reports() {
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Reports
      </Typography>
      <Grid container spacing={3}>
        {reportCards.map((card) => {
          const Icon = card.icon;
          return (
            <Grid item xs={12} sm={6} md={4} key={card.title}>
              <Card sx={{ height: '100%' }}>
                <CardActionArea 
                  onClick={() => navigate(card.path)}
                  sx={{ height: '100%' }}
                >
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Icon sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
                      <Typography variant="h6" component="h2">
                        {card.title}
                      </Typography>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      {card.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

export default Reports;
