export const EXPENSE_TYPES = {
  ELECTRICITY: 'Electricity Bill',
  MAINTENANCE: 'Maintenance',
  PARKING: 'Parking',
  INTERNET: 'Internet',
  SALARY: 'Salary',
  PROPERTY_TAX: 'Property Tax',
  INSURANCE: 'Insurance',
  WATER: 'Water Bill',
  SECURITY: 'Security',
  CLEANING: 'Cleaning',
  OTHER: 'Other'
};

export const EXPENSE_FREQUENCIES = {
  ONE_TIME: 'One Time',
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly'
};
