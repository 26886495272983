import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../store/slices/authSlice';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../firebase';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Sign in with Firebase Auth
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      // Query Firestore for user document by email
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('email', '==', userCredential.user.email));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const userData = userDoc.data();
        // console.log('User data:', userData);
        // Dispatch user data to Redux store
        dispatch(login({
          uid: userCredential.user.uid,
          email: userCredential.user.email,
          fullName: userData.fullName,
          organizationId: userData.organizationId,
          phoneNumber: userData.phoneNumber,
          createdAt: userData.createdAt
        }));
        navigate('/');
      } else {
        setError('User data not found');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Invalid email or password');
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#F3F2EF',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          p: 0,
          background: 'linear-gradient(180deg, #013F2E 50%, #F3F2EF 50%)',
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              bgcolor: 'white',
              borderRadius: 2,
              overflow: 'hidden',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            {/* Left side - Illustration */}
            <Box
              sx={{
                flex: 1,
                bgcolor: '#F3F2EF',
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center',
                justifyContent: 'center',
                p: 4,
              }}
            >
              <Box
                component="img"
                src="/coworking-Illustration.png"
                alt="Office illustration"
                sx={{
                  width: '100%',
                  // maxWidth: 400,
                  height: 'auto',
                }}
              />
            </Box>

            {/* Right side - Login form */}
            <Box
              sx={{
                flex: 1,
                p: 6,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ mb: 6 }}>
                <Box
                  component="img"
                  src="https://assets.gofloaters.com/logo.png"
                  alt="GoFloaters"
                  sx={{
                    height: '50px',
                    width: 'auto',
                    mb: 4,
                  }}
                />
                <Typography variant="h1" sx={{ fontWeight: 600, mb: 1 }}>
                Portfolio Management
                </Typography>
              </Box>

              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Email or Phone"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!error}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  label="Password"
                  type="password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={!!error}
                  helperText={error}
                  sx={{ mb: 2 }}
                />

                <Link
                  href="#"
                  variant="body2"
                  sx={{
                    display: 'block',
                    mb: 3,
                    color: 'primary.main',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Forgot password?
                </Link>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{
                    mb: 2,
                    height: '48px',
                    textTransform: 'none',
                    fontSize: '1rem',
                  }}
                >
                  Sign in
                </Button>

                <Button
                  fullWidth
                  variant="outlined"
                  size="large"
                  onClick={() => navigate('/signup')}
                  sx={{
                    height: '48px',
                    textTransform: 'none',
                    fontSize: '1rem',
                  }}
                >
                  Sign up
                </Button>
              </form>
            </Box>
          </Box>

          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              GoFloaters &copy; 2024
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default Login;
