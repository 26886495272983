import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../store/slices/authSlice';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';

function Signup() {
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!fullName.trim()) {
      setError('Full Name is required');
      return;
    }

    if (!organizationName.trim()) {
      setError('Organization Name is required');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Store user data in Firestore
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        email: userCredential.user.email,
        fullName: fullName.trim(),
        organizationName: organizationName.trim(),
        createdAt: new Date().toISOString(),
      });

      dispatch(login({
        ...userCredential.user,
        fullName: fullName.trim(),
        organizationName: organizationName.trim()
      }));
      navigate('/');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setError('Email already in use');
      } else if (error.code === 'auth/weak-password') {
        setError('Password should be at least 6 characters');
      } else {
        setError('Failed to create account');
        console.error('Signup error:', error);
      }
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#F3F2EF',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          p: 3,
          background: 'linear-gradient(180deg, #0073B1 50%, #F3F2EF 50%)',
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              bgcolor: 'white',
              borderRadius: 2,
              overflow: 'hidden',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            {/* Left side - Illustration */}
            <Box
              sx={{
                flex: 1,
                bgcolor: '#F3F2EF',
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center',
                justifyContent: 'center',
                p: 4,
              }}
            >
              <Box
                component="img"
                src="/coworking-Illustration.png"
                alt="Office illustration"
                sx={{
                  width: '100%',
                  // maxWidth: 400,
                  height: 'auto',
                }}
              />
            </Box>

            {/* Right side - Signup form */}
            <Box
              sx={{
                flex: 1,
                p: 6,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ mb: 6 }}>
                <Box
                  component="img"
                  src="https://assets.gofloaters.com/logo.png"
                  alt="GoFloaters"
                  sx={{
                    height: '40px',
                    width: 'auto',
                    mb: 4,
                  }}
                />
                <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
                  Create Account
                </Typography>
              </Box>

              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  required
                  label="Full Name"
                  variant="outlined"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  error={!!error && error.includes('Full Name')}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  required
                  label="Organization Name"
                  variant="outlined"
                  value={organizationName}
                  onChange={(e) => setOrganizationName(e.target.value)}
                  error={!!error && error.includes('Organization')}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  required
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!error && error.includes('Email')}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  required
                  label="Password"
                  type="password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={!!error && (error.includes('Password') || error.includes('match'))}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  required
                  label="Confirm Password"
                  type="password"
                  variant="outlined"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  error={!!error && error.includes('match')}
                  helperText={error}
                  sx={{ mb: 3 }}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{
                    mb: 2,
                    height: '48px',
                    textTransform: 'none',
                    fontSize: '1rem',
                  }}
                >
                  Create Account
                </Button>

                <Button
                  fullWidth
                  variant="outlined"
                  size="large"
                  onClick={() => navigate('/login')}
                  sx={{
                    height: '48px',
                    textTransform: 'none',
                    fontSize: '1rem',
                  }}
                >
                  Sign in instead
                </Button>
              </form>
            </Box>
          </Box>

          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              GoFloaters 2024
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default Signup;
