import React, { useState, useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AuthProvider } from './context/AuthContext';
import Layout from './components/Layout';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import Properties from './pages/Properties';
import Reports from './pages/Reports';
import Payments from './pages/Payments';
import Expenses from './pages/Expenses';
import Recommendations from './pages/Recommendations';
import Benchmarking from './pages/Benchmarking';
import Compliance from './pages/Compliance';
import Notifications from './pages/Notifications';
import Account from './pages/Account';
import PortfolioSummary from './pages/PortfolioSummary';
import PortfolioPerformance from './pages/PortfolioPerformance';
import RentRenewalAnalysis from './pages/RentRenewalAnalysis';
import IndustryReports from './pages/IndustryReports';
import DocumentVault from './pages/DocumentVault';
import Settings from './pages/Settings';
import ServiceRequests from './pages/ServiceRequests';
import ProtectedRoute from './components/ProtectedRoute';
import getTheme from './theme';

export const ColorModeContext = React.createContext({ 
  toggleColorMode: () => {} 
});

function AppContent() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [mode, setMode] = useState('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = useMemo(() => getTheme(mode), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate to="/dashboard" replace />} />
          <Route path="/signup" element={!isAuthenticated ? <Signup /> : <Navigate to="/dashboard" replace />} />
          <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/properties" element={<Properties />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/expenses" element={<Expenses />} />
            <Route path="/recommendations" element={<Recommendations />} />
            <Route path="/benchmarking" element={<Benchmarking />} />
            <Route path="/compliance" element={<Compliance />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/account" element={<Account />} />
            <Route path="/reports/portfolio-summary" element={<PortfolioSummary />} />
            <Route path="/portfolio-performance" element={<PortfolioPerformance />} />
            <Route path="/reports/rent-renewal-analysis" element={<RentRenewalAnalysis />} />
            <Route path="/industry-reports" element={<IndustryReports />} />
            <Route path="/document-vault" element={<DocumentVault />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/service-requests" element={<ServiceRequests />} />
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

function App() {
  return (
    <AuthProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AppContent />
      </LocalizationProvider>
    </AuthProvider>
  );
}

export default App;
