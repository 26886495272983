import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uid: null,
  email: null,
  fullName: null,
  organizationId: null,
  phoneNumber: null,
  createdAt: null,
  isAuthenticated: false,
  loading: false,
  error: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.uid = action.payload.uid;
      state.email = action.payload.email;
      state.fullName = action.payload.fullName;
      state.organizationId = action.payload.organizationId;
      state.phoneNumber = action.payload.phoneNumber;
      state.createdAt = action.payload.createdAt;
      state.error = null;
      state.loading = false;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.uid = null;
      state.email = null;
      state.fullName = null;
      state.organizationId = null;
      state.phoneNumber = null;
      state.createdAt = null;
      state.error = null;
      state.loading = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    updateProfile: (state, action) => {
      state.fullName = action.payload.fullName || state.fullName;
      state.phoneNumber = action.payload.phoneNumber || state.phoneNumber;
    }
  }
});

export const { login, logout, setLoading, setError, updateProfile } = authSlice.actions;

export default authSlice.reducer;
