import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  Avatar,
  Paper,
  Divider,
  Chip,
} from '@mui/material';
import {
  Notifications as NotificationsIcon,
  Payment as PaymentIcon,
  Warning as WarningIcon,
  Info as InfoIcon,
  Check as CheckIcon,
} from '@mui/icons-material';

// Dummy notifications data
const notifications = [
  {
    id: 1,
    type: 'payment',
    title: 'Rent Payment Received',
    message: 'Rent payment of ₹45,000 received from Enterprise Square',
    timestamp: '2 hours ago',
    read: false,
    icon: <PaymentIcon color="success" />,
  },
  {
    id: 2,
    type: 'alert',
    title: 'Maintenance Due',
    message: 'Scheduled maintenance for Tech Hub Coworking is due next week',
    timestamp: '5 hours ago',
    read: false,
    icon: <WarningIcon color="warning" />,
  },
  {
    id: 3,
    type: 'info',
    title: 'New Property Added',
    message: 'Corporate Plaza has been successfully added to your portfolio',
    timestamp: '1 day ago',
    read: true,
    icon: <InfoIcon color="info" />,
  },
  {
    id: 4,
    type: 'success',
    title: 'Agreement Renewed',
    message: 'Lease agreement for Startup Hub has been renewed successfully',
    timestamp: '2 days ago',
    read: true,
    icon: <CheckIcon color="success" />,
  },
  {
    id: 5,
    type: 'alert',
    title: 'Security Deposit Due',
    message: 'Security deposit for Business Center One is pending',
    timestamp: '3 days ago',
    read: true,
    icon: <WarningIcon color="error" />,
  },
];

function Notifications() {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Notifications
      </Typography>

      <Paper elevation={0} variant="outlined">
        <List sx={{ width: '100%' }}>
          {notifications.map((notification, index) => (
            <React.Fragment key={notification.id}>
              <ListItem
                alignItems="flex-start"
                sx={{
                  bgcolor: notification.read ? 'transparent' : 'action.hover',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'background.paper' }}>
                    {notification.icon}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {notification.title}
                      {!notification.read && (
                        <Chip
                          label="New"
                          size="small"
                          color="primary"
                          sx={{ height: 20 }}
                        />
                      )}
                    </Box>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.primary"
                        sx={{ display: 'block', mb: 0.5 }}
                      >
                        {notification.message}
                      </Typography>
                      <Typography
                        component="span"
                        variant="caption"
                        color="text.secondary"
                      >
                        {notification.timestamp}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              {index < notifications.length - 1 && <Divider variant="inset" component="li" />}
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </Box>
  );
}

export default Notifications;
