import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { store } from '../store';
import { setProperties, setLoading, setError } from '../redux/reducers/propertyReducer';

let propertiesUnsubscribe = null;

export const subscribeToProperties = (organizationId) => {
  console.log('Subscribing to properties for org:', organizationId);

  // Unsubscribe from previous subscription if exists
  if (propertiesUnsubscribe) {
    propertiesUnsubscribe();
  }

  if (!organizationId) {
    console.log('No organizationId provided');
    store.dispatch(setError('No organization ID provided'));
    return;
  }

  store.dispatch(setLoading(true));

  const propertiesQuery = query(
    collection(db, 'properties'),
    where('organizationId', '==', organizationId)
  );

  propertiesUnsubscribe = onSnapshot(propertiesQuery, (snapshot) => {
    console.log('Properties snapshot received:', snapshot.size, 'documents');
    const properties = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    
    console.log('Dispatching properties to Redux:', properties);
    store.dispatch(setProperties(properties));
  }, (error) => {
    console.error('Error in properties subscription:', error);
    store.dispatch(setError(error.message));
  });

  return propertiesUnsubscribe;
};

export const unsubscribeFromProperties = () => {
  if (propertiesUnsubscribe) {
    console.log('Unsubscribing from properties');
    propertiesUnsubscribe();
    propertiesUnsubscribe = null;
  }
};
