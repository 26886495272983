import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore,connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDmQAx544CCl7eRuwWZtcklkMGQIfid1II",
  authDomain: "gofloaters-property-manager.firebaseapp.com",
  projectId: "gofloaters-property-manager",
  // storageBucket: "gofloaters-property-manager.appspot.com",
  storageBucket: "gofloaters-property-manager.firebasestorage.app",
  messagingSenderId: "1092582799445",
  appId: "1:1092582799445:web:a69afaeccac6e857f17cdc",
  measurementId: "G-MV2KCFEE2K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Storage with custom settings
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Connect to emulators in development
if (process.env.REACT_APP_ENV === 'development') {
  // Connect to Firestore emulator
  connectFirestoreEmulator(db, 'localhost', 8080);
  console.log('Connected to Firestore emulator');
}

// Set authentication persistence to LOCAL
setPersistence(auth, browserLocalPersistence)
  .catch((error) => {
    console.error("Error setting auth persistence:", error);
  });

export { auth, db, storage };
