import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Paper,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Stack,
  Autocomplete,
  TextField,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Alert,
  Snackbar,
  Divider
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { db } from '../firebase';
import { collection, query, where, getDocs, addDoc, Timestamp, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { EXPENSE_TYPES, EXPENSE_FREQUENCIES } from '../constants/expenses';

function Expenses() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [propertyFilter, setPropertyFilter] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [expenses, setExpenses] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editingExpense, setEditingExpense] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [expenseToDelete, setExpenseToDelete] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Form state
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [expenseName, setExpenseName] = useState('');
  const [expenseType, setExpenseType] = useState('');
  const [amount, setAmount] = useState('');
  const [frequency, setFrequency] = useState('');
  const [expenseDate, setExpenseDate] = useState(dayjs());
  const [description, setDescription] = useState('');

  const { properties } = useSelector((state) => state.property);
  const { organizationId } = useSelector((state) => state.auth);

  useEffect(() => {
    fetchExpenses();
  }, [organizationId]);

  const fetchExpenses = async () => {
    if (!organizationId) return;

    try {
      const expensesData = [];
      for (const property of properties) {
        const expensesRef = collection(db, 'properties', property.id, 'expenses');
        const q = query(expensesRef, where('organizationId', '==', organizationId));
        const querySnapshot = await getDocs(q);
        
        querySnapshot.forEach((doc) => {
          expensesData.push({
            id: doc.id,
            ...doc.data(),
            propertyName: property.propertyName,
          });
        });
      }
      setExpenses(expensesData);
    } catch (error) {
      console.error('Error fetching expenses:', error);
    }
  };

  const handleAddExpense = async () => {
    if (!selectedProperty || !expenseName || !expenseType || !amount || !frequency || !expenseDate) {
      return;
    }

    setLoading(true);
    try {
      const expenseData = {
        propertyId: selectedProperty.id,
        name: expenseName,
        type: expenseType,
        amount: parseFloat(amount),
        frequency,
        date: Timestamp.fromDate(expenseDate.toDate()),
        description,
        organizationId,
        createdAt: Timestamp.now(),
      };

      const expensesRef = collection(db, 'properties', selectedProperty.id, 'expenses');
      await addDoc(expensesRef, expenseData);
      
      // Reset form and close modal
      handleCloseModal();
      // Refresh expenses list
      fetchExpenses();
    } catch (error) {
      console.error('Error adding expense:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (expense) => {
    const propertyOption = properties.find(p => p.id === expense.propertyId);
    setSelectedProperty(propertyOption);
    setExpenseName(expense.name);
    setExpenseType(expense.type);
    setAmount(expense.amount.toString());
    setFrequency(expense.frequency);
    setExpenseDate(dayjs(expense.date.toDate()));
    setDescription(expense.description || '');
    setEditingExpense(expense);
    setOpenModal(true);
  };

  const handleDeleteClick = (expense) => {
    setExpenseToDelete(expense);
    setDeleteConfirmOpen(true);
  };

  const handleUpdateExpense = async () => {
    if (!selectedProperty || !expenseName || !expenseType || !amount || !frequency || !expenseDate) {
      return;
    }

    setLoading(true);
    try {
      const expenseData = {
        propertyId: selectedProperty.id,
        name: expenseName,
        type: expenseType,
        amount: parseFloat(amount),
        frequency,
        date: Timestamp.fromDate(expenseDate.toDate()),
        description,
        organizationId,
        updatedAt: Timestamp.now(),
      };

      const expenseRef = doc(db, 'properties', selectedProperty.id, 'expenses', editingExpense.id);
      await updateDoc(expenseRef, expenseData);
      
      handleCloseModal();
      setSnackbar({
        open: true,
        message: 'Expense updated successfully',
        severity: 'success'
      });
      fetchExpenses();
    } catch (error) {
      console.error('Error updating expense:', error);
      setSnackbar({
        open: true,
        message: 'Error updating expense',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteExpense = async () => {
    if (!expenseToDelete) return;

    try {
      const expenseRef = doc(db, 'properties', expenseToDelete.propertyId, 'expenses', expenseToDelete.id);
      await deleteDoc(expenseRef);
      
      setDeleteConfirmOpen(false);
      setExpenseToDelete(null);
      setSnackbar({
        open: true,
        message: 'Expense deleted successfully',
        severity: 'success'
      });
      fetchExpenses();
    } catch (error) {
      console.error('Error deleting expense:', error);
      setSnackbar({
        open: true,
        message: 'Error deleting expense',
        severity: 'error'
      });
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedProperty(null);
    setExpenseName('');
    setExpenseType('');
    setAmount('');
    setFrequency('');
    setExpenseDate(dayjs());
    setDescription('');
    setEditingExpense(null);
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const propertyOptions = properties.map(property => ({
    id: property.id,
    label: property.propertyName
  })).sort((a, b) => a.label.localeCompare(b.label));

  const columns = [
    { 
      id: 'date', 
      label: 'Date', 
      minWidth: 100,
      format: (value) => dayjs(value.toDate()).format('DD/MM/YYYY')
    },
    { 
      id: 'propertyName', 
      label: 'Property', 
      minWidth: 170 
    },
    {
      id: 'name',
      label: 'Expense Name',
      minWidth: 170
    },
    { 
      id: 'type', 
      label: 'Type', 
      minWidth: 130,
      format: (value) => (
        <Chip 
          label={value} 
          size="small"
          color={
            value === EXPENSE_TYPES.ELECTRICITY ? 'primary' :
            value === EXPENSE_TYPES.MAINTENANCE ? 'secondary' :
            value === EXPENSE_TYPES.PARKING ? 'success' :
            value === EXPENSE_TYPES.INTERNET ? 'warning' :
            'default'
          }
        />
      )
    },
    { 
      id: 'frequency', 
      label: 'Frequency', 
      minWidth: 120 
    },
    { 
      id: 'description', 
      label: 'Description', 
      minWidth: 200 
    },
    { 
      id: 'amount', 
      label: 'Amount', 
      minWidth: 130,
      format: (value) => `₹${value.toLocaleString('en-IN')}`
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 100,
      format: (_, row) => (
        <Stack direction="row" spacing={1}>
          <IconButton 
            size="small" 
            color="primary"
            onClick={() => handleEditClick(row)}
          >
            <EditIcon />
          </IconButton>
          <IconButton 
            size="small" 
            color="error"
            onClick={() => handleDeleteClick(row)}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      )
    }
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>

        
          {/* Toolbar */}
          <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
            <Stack 
              direction="row" 
              spacing={2} 
              alignItems="center"
              sx={{ mb: 1 }}
            >
              <Autocomplete
                value={propertyFilter}
                onChange={(event, newValue) => setPropertyFilter(newValue)}
                options={propertyOptions}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    placeholder="Search expenses..."
                    size="small" 
                    sx={{ 
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 2,
                        bgcolor: 'grey.50'
                      }
                    }} 
                  />
                )}
              />
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={setStartDate}
                slotProps={{ 
                  textField: { 
                    size: "small",
                    sx: { 
                      width: 200,
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 2
                      }
                    }
                  } 
                }}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={setEndDate}
                slotProps={{ 
                  textField: { 
                    size: "small",
                    sx: { 
                      width: 200,
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 2
                      }
                    }
                  } 
                }}
              />
              <Box sx={{ flexGrow: 1 }} />
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setOpenModal(true)}
                sx={{ 
                  height: 40,
                  borderRadius: 2,
                  textTransform: 'none',
                  px: 3,
                  bgcolor: 'primary.dark',
                  '&:hover': {
                    bgcolor: 'primary.main',
                  }
                }}
              >
                Add Expense
              </Button>
            </Stack>
          </Box>

          <Divider />

          {/* Table */}
          <TableContainer>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                      sx={{
                        bgcolor: 'grey.50',
                        fontWeight: 600,
                        py: 1.5
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {expenses
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow 
                      hover 
                      key={row.id}
                      sx={{
                        '&:hover': {
                          bgcolor: 'action.hover',
                        }
                      }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell 
                            key={column.id}
                            sx={{ py: 1 }}
                          >
                            {column.format && typeof column.format === 'function'
                              ? column.format(value, row)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                {expenses.length === 0 && (
                  <TableRow>
                    <TableCell 
                      colSpan={columns.length} 
                      align="center"
                      sx={{ py: 8 }}
                    >
                      <Typography variant="body1" color="text.secondary">
                        No expenses found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Divider />

          {/* Pagination */}
          <Box sx={{ 
            display: 'flex',
            justifyContent: 'flex-end',
            p: 1
          }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={expenses.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
          maxWidth="sm"
          PaperProps={{
            sx: { borderRadius: 2 }
          }}
        >
          <DialogTitle>Delete Expense</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this expense?
              {expenseToDelete && (
                <Box component="span" sx={{ fontWeight: 'bold' }}>
                  {` "${expenseToDelete.name}"`}
                </Box>
              )}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
            <Button 
              onClick={handleDeleteExpense} 
              color="error" 
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* Add/Edit Expense Modal */}
        <Dialog 
          open={openModal} 
          onClose={handleCloseModal}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: { borderRadius: 2 }
          }}
        >
          <DialogTitle>
            {editingExpense ? 'Edit Expense' : 'Add New Expense'}
          </DialogTitle>
          <DialogContent>
            <Stack spacing={3} sx={{ mt: 1 }}>
              <Autocomplete
                value={selectedProperty}
                onChange={(event, newValue) => setSelectedProperty(newValue)}
                options={propertyOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Property" required />
                )}
              />

              <TextField
                label="Expense Name"
                value={expenseName}
                onChange={(e) => setExpenseName(e.target.value)}
                required
                fullWidth
              />
              
              <FormControl fullWidth required>
                <InputLabel>Type of Expense</InputLabel>
                <Select
                  value={expenseType}
                  onChange={(e) => setExpenseType(e.target.value)}
                  label="Type of Expense"
                >
                  {Object.values(EXPENSE_TYPES).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                label="Amount"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                required
                InputProps={{
                  startAdornment: <Typography color="text.secondary">₹</Typography>
                }}
              />

              <FormControl fullWidth required>
                <InputLabel>Frequency</InputLabel>
                <Select
                  value={frequency}
                  onChange={(e) => setFrequency(e.target.value)}
                  label="Frequency"
                >
                  {Object.values(EXPENSE_FREQUENCIES).map((freq) => (
                    <MenuItem key={freq} value={freq}>
                      {freq}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <DatePicker
                label="Date"
                value={expenseDate}
                onChange={setExpenseDate}
                slotProps={{ 
                  textField: { 
                    required: true,
                    fullWidth: true
                  } 
                }}
              />

              <TextField
                label="Description"
                multiline
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button 
              onClick={editingExpense ? handleUpdateExpense : handleAddExpense} 
              variant="contained"
              disabled={loading || !selectedProperty || !expenseName || !expenseType || !amount || !frequency || !expenseDate}
            >
              {loading ? (editingExpense ? 'Updating...' : 'Adding...') : (editingExpense ? 'Update Expense' : 'Add Expense')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert 
            onClose={handleCloseSnackbar} 
            severity={snackbar.severity}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>

    </Paper>
  );
}

export default Expenses;
