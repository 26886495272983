import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Link,
  Divider,
  CircularProgress,
} from '@mui/material';
import {
  Article as ArticleIcon,
  OpenInNew as OpenInNewIcon,
} from '@mui/icons-material';

// Sample news data - In a real app, this would come from an API
const sampleNews = [
  {
    id: 1,
    title: "Commercial Real Estate Market Shows Signs of Recovery",
    source: "Real Estate Weekly",
    date: "2024-01-15",
    url: "#",
  },
  {
    id: 2,
    title: "New Property Tax Regulations Coming in 2024",
    source: "Property Management Today",
    date: "2024-01-14",
    url: "#",
  },
  {
    id: 3,
    title: "Sustainable Building Practices Gain Momentum",
    source: "Green Building News",
    date: "2024-01-13",
    url: "#",
  },
  {
    id: 4,
    title: "Real Estate Tech Trends to Watch in 2024",
    source: "PropTech Insider",
    date: "2024-01-12",
    url: "#",
  },
  {
    id: 5,
    title: "Housing Market Forecast for Q1 2024",
    source: "Real Estate Journal",
    date: "2024-01-11",
    url: "#",
  },
];

const NewsItem = ({ title, source, date, url }) => (
  <Box sx={{ mb: 2 }}>
    <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
      <ArticleIcon sx={{ mr: 1, mt: 0.3, color: 'primary.main' }} fontSize="small" />
      <Box>
        <Link
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: 'text.primary',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
              color: 'primary.main',
            },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle2" component="span">
            {title}
          </Typography>
          <OpenInNewIcon sx={{ ml: 0.5, fontSize: 14 }} />
        </Link>
        <Typography variant="caption" color="text.secondary" display="block">
          {source} • {new Date(date).toLocaleDateString()}
        </Typography>
      </Box>
    </Box>
    <Divider sx={{ mt: 2 }} />
  </Box>
);

function NewsFeed() {
  return (
    <Card sx={{ height: '100%', bgcolor: 'background.paper' }}>
      <CardContent>
        <Typography variant="h6" component="h2" sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
          Industry News
        </Typography>
        <Box sx={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto', pr: 1 }}>
          {sampleNews.map((news) => (
            <NewsItem key={news.id} {...news} />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}

export default NewsFeed;
