import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import settingsReducer from './redux/reducers/settingsReducer';
import propertyReducer from './redux/reducers/propertyReducer';
import authReducer from './store/slices/authSlice';

const rootReducer = combineReducers({
  settings: settingsReducer,
  property: propertyReducer,
  auth: authReducer
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'settings'], // only auth and settings will be persisted
  blacklist: ['property'] // property state will not be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureAppStore = () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
        },
      }),
    devTools: process.env.NODE_ENV !== 'production',
  });

  const persistor = persistStore(store);

  return { store, persistor };
};

const { store, persistor } = configureAppStore();

export { store, persistor };
