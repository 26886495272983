import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Alert,
  CircularProgress,
  Card,
  CardContent,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import moment from 'moment';
import { formatIndianRupees } from '../utils/currencyFormatter';

// Sample managed properties from Properties page
const sampleData = [
  {
    id: 1,
    propertyName: 'Tech Hub Coworking',
    propertyType: 'Coworking',
    city: 'Chennai',
    rentPerMonth: 150000,
    locality: 'T Nagar',
    agreementStartDate: '2023-10-15',
    agreementEndDate: '2024-10-14',
    operatorName: 'WeWork',
    securityDeposit: 450000,
    billableSeats: 20,
    status: 'Occupied',
    rentPerSeat: 7500,
    rentPerSqFt: 60,
    size: 2500,
    benchmarkComparison: {
      rentPerSeatDiff: -3.85,
      rentPerSqFtDiff: -9.09,
      securityDepositDiff: -6.25,
      sizeDiff: -10.71,
      marketPosition: 'Competitive',
      pricePercentile: 45,
      similarProperties: [4, 6, 10]
    }
  },
  {
    id: 4,
    propertyName: 'Innovation Hub',
    propertyType: 'Coworking',
    city: 'Delhi',
    rentPerMonth: 160000,
    locality: 'Connaught Place',
    agreementStartDate: '2023-11-01',
    agreementEndDate: '2024-10-31',
    operatorName: 'WeWork',
    securityDeposit: 480000,
    billableSeats: 22,
    status: 'Occupied',
    rentPerSeat: 7800,
    rentPerSqFt: 65,
    size: 2600,
    benchmarkComparison: {
      rentPerSeatDiff: 5.2,
      rentPerSqFtDiff: 8.33,
      securityDepositDiff: 4.35,
      sizeDiff: 0,
      marketPosition: 'Premium',
      pricePercentile: 65,
      similarProperties: [1, 7, 8]
    }
  },
  ,
  {
    id: 2,
    propertyName: 'Enterprise Square',
    propertyType: 'Managed Office',
    city: 'Bangalore',
    rentPerMonth: 270000,
    locality: 'Koramangala',
    agreementStartDate: '2023-09-30',
    agreementEndDate: '2024-09-29',
    operatorName: 'Indiqube',
    securityDeposit: 810000,
    billableSeats: 30,
    status: 'Vacant',
    rentPerSeat: 9000,
    rentPerSqFt: 90,
    size: 3000,
    benchmarkComparison: {
      rentPerSeatDiff: 7.14,
      rentPerSqFtDiff: -6.25,
      securityDepositDiff: -3.57,
      sizeDiff: -6.25,
      marketPosition: 'Premium',
      pricePercentile: 75,
      similarProperties: [9, 11, 14]
    }
  },
  {
    id: 3,
    propertyName: 'Corporate Plaza',
    propertyType: 'Conventional Office',
    city: 'Mumbai',
    rentPerMonth: 35000,
    locality: 'Bandra',
    agreementStartDate: '2023-10-01',
    agreementEndDate: '2024-09-30',
    operatorName: 'Bob Johnson',
    securityDeposit: 105000,
    billableSeats: 25,
    status: 'Occupied',
    rentPerSeat: 1400,
    rentPerSqFt: 100,
    size: 3500,
    benchmarkComparison: {
      rentPerSqFtDiff: -9.09,
      securityDepositDiff: -34.38,
      sizeDiff: -22.22,
      marketPosition: 'Value',
      pricePercentile: 20,
      similarProperties: [7, 8]
    }
  },
  {
    id: 5,
    propertyName: 'Business Center One',
    propertyType: 'Managed Office',
    city: 'Hyderabad',
    rentPerMonth: 315000,
    locality: 'Hitech City',
    agreementStartDate: '2023-10-10',
    agreementEndDate: '2024-10-09',
    operatorName: 'Smartworks',
    securityDeposit: 945000,
    billableSeats: 35,
    status: 'Occupied',
    rentPerSeat: 9000,
    rentPerSqFt: 126,
    size: 2500,
  },
  {
    id: 6,
    propertyName: 'Digital Square',
    propertyType: 'Coworking',
    city: 'Delhi',
    rentPerMonth: 168000,
    locality: 'Nehru Place',
    agreementStartDate: '2023-12-01',
    agreementEndDate: '2024-11-30',
    operatorName: 'Innov8',
    securityDeposit: 504000,
    billableSeats: 24,
    status: 'Occupied',
    rentPerSeat: 7000,
    rentPerSqFt: 70,
    size: 2400,
    benchmarkComparison: {
      rentPerSeatDiff: -2.78,
      rentPerSqFtDiff: 16.67,
      securityDepositDiff: 9.57,
      sizeDiff: -7.69,
      marketPosition: 'Competitive',
      pricePercentile: 55,
      similarProperties: [1, 4, 10]
    }
  },
  {
    id: 7,
    propertyName: 'Redwood Residency',
    propertyType: 'Conventional Office',
    city: 'Chennai',
    rentPerMonth: 28000,
    locality: 'Anna Nagar',
    agreementStartDate: '2023-09-25',
    agreementEndDate: '2024-09-24',
    operatorName: 'David Lee',
    securityDeposit: 84000,
    billableSeats: 18,
    status: 'Vacant',
    rentPerSqFt: 85,
    size: 3300,
  },
  {
    id: 8,
    propertyName: 'Startup Central',
    propertyType: 'Coworking',
    city: 'Bangalore',
    rentPerMonth: 184000,
    locality: 'Indiranagar',
    agreementStartDate: '2023-09-15',
    agreementEndDate: '2024-09-14',
    operatorName: 'Innov8',
    securityDeposit: 552000,
    billableSeats: 23,
    status: 'Occupied',
    rentPerSeat: 8000,
    rentPerSqFt: 75,
    size: 2450,
    benchmarkComparison: {
      rentPerSeatDiff: 14.29,
      rentPerSqFtDiff: 25.0,
      securityDepositDiff: 20.0,
      sizeDiff: -5.77,
      marketPosition: 'Premium',
      pricePercentile: 85,
      similarProperties: [4, 6, 10]
    }
  },
  {
    id: 9,
    propertyName: 'Elm Executive Hub',
    propertyType: 'Managed Office',
    city: 'Bangalore',
    rentPerMonth: 320000,
    locality: 'Electronic City',
    agreementStartDate: '2023-09-20',
    agreementEndDate: '2024-09-19',
    operatorName: 'Tablespace',
    securityDeposit: 960000,
    billableSeats: 32,
    status: 'Maintenance',
    rentPerSeat: 10000,
    rentPerSqFt: 107,
    size: 3000,
  },
  {
    id: 10,
    propertyName: 'Nexus Workspace',
    propertyType: 'Coworking',
    city: 'Pune',
    rentPerMonth: 165000,
    locality: 'Viman Nagar',
    agreementStartDate: '2023-10-01',
    agreementEndDate: '2024-09-30',
    operatorName: 'Cowrks',
    securityDeposit: 495000,
    billableSeats: 22,
    status: 'Occupied',
    rentPerSeat: 7500,
    rentPerSqFt: 68,
    size: 2425,
    benchmarkComparison: {
      rentPerSeatDiff: 7.14,
      rentPerSqFtDiff: 13.33,
      securityDepositDiff: 7.61,
      sizeDiff: -6.73,
      marketPosition: 'Premium',
      pricePercentile: 70,
      similarProperties: [1, 4, 6]
    }
  },
  {
    id: 11,
    propertyName: 'Aspen Analytics Park',
    propertyType: 'Managed Office',
    city: 'Hyderabad',
    rentPerMonth: 342000,
    locality: 'Gachibowli',
    agreementStartDate: '2023-10-03',
    agreementEndDate: '2024-10-02',
    operatorName: 'Indiqube',
    securityDeposit: 1026000,
    billableSeats: 38,
    status: 'Occupied',
    rentPerSeat: 9000,
    rentPerSqFt: 114,
    size: 3000,
  },
  {
    id: 12,
    propertyName: 'Juniper Junction',
    propertyType: 'Coworking',
    city: 'Pune',
    rentPerMonth: 36000,
    locality: 'Kalyani Nagar',
    agreementStartDate: '2023-09-28',
    agreementEndDate: '2024-09-27',
    operatorName: 'Ava Chen',
    securityDeposit: 108000,
    billableSeats: 24,
    status: 'Vacant',
    rentPerSeat: 1500,
    rentPerSqFt: 12,
    size: 3000,
  },
  {
    id: 13,
    propertyName: 'Cypress Center',
    propertyType: 'Managed Office',
    city: 'Chennai',
    rentPerMonth: 44000,
    locality: 'Teynampet',
    agreementStartDate: '2023-10-07',
    agreementEndDate: '2024-10-06',
    operatorName: 'Smartworks',
    securityDeposit: 132000,
    billableSeats: 34,
    status: 'Occupied',
    rentPerSeat: 1294,
    rentPerSqFt: 15,
    size: 2900,
  },
  {
    id: 14,
    propertyName: 'Banyan Business Hub',
    propertyType: 'Managed Office',
    city: 'Mumbai',
    rentPerMonth: 50000,
    locality: 'Lower Parel',
    agreementStartDate: '2023-09-22',
    agreementEndDate: '2024-09-21',
    operatorName: 'Tablespace',
    securityDeposit: 150000,
    billableSeats: 42,
    status: 'Maintenance',
    rentPerSeat: 1190,
    rentPerSqFt: 17,
    size: 2900,
  },
  {
    id: 15,
    propertyName: 'Palm Paradise',
    propertyType: 'Coworking',
    city: 'Bangalore',
    rentPerMonth: 33000,
    locality: 'Indiranagar',
    agreementStartDate: '2023-10-13',
    agreementEndDate: '2024-10-12',
    operatorName: 'Innov8',
    securityDeposit: 99000,
    billableSeats: 20,
    status: 'Occupied',
    rentPerSeat: 1650,
    rentPerSqFt: 11,
    size: 3000,
  }
];

// Sample market data from GoFloaters (would come from API)
const marketData = {
  'Chennai-T Nagar-Coworking': [
    {
      propertyName: 'WorkSpace Pro',
      rentPerSeat: 7000,
      location:'Teynampet',
      amenities: ['High Speed Internet', '24/7 Access', 'Meeting Rooms'],
      rating: 4.5,
      similarityScore:80
    },
    {
      propertyName: 'The Office Hub',
      rentPerSeat: 6500,
      location:'T Nagar',
      amenities: ['Cafeteria', 'Parking', 'Meeting Rooms'],
      rating: 4.2,
      similarityScore:95
    },
    {
      propertyName: 'Executive Zone',
      rentPerSeat: 7000,
      location:'Anna Salai',
      amenities: ['Cafeteria', 'Parking', 'Meeting Rooms'],
      rating: 4.6,
      similarityScore:80
    },
  ],
  'Chennai-Alwarpet-Coworking': [
    {
      propertyName: 'Collaborative Space',
      rentPerSeat: 8000,
      location: 'Alwarpet',
      amenities: ['High Speed Internet', 'Meeting Rooms', 'Cafeteria'],
      rating: 4.4,
      similarityScore: 90
    },
    {
      propertyName: 'Business Center Plus',
      rentPerSeat: 7500,
      location: 'Mylapore',
      amenities: ['24/7 Access', 'Parking', 'Meeting Rooms'],
      rating: 4.3,
      similarityScore: 85
    },
    {
      propertyName: 'Urban Works',
      rentPerSeat: 7800,
      location: 'RA Puram',
      amenities: ['High Speed Internet', 'Cafeteria', 'Meeting Rooms'],
      rating: 4.5,
      similarityScore: 80
    }
  ],
  'Delhi-Connaught Place-Coworking': [
    {
      propertyName: 'Innovation Hub',
      rentPerSeat: 7800,
      location: 'Connaught Place',
      amenities: ['High Speed Internet', '24/7 Access', 'Meeting Rooms'],
      rating: 4.5,
      similarityScore: 90
    },
    {
      propertyName: 'The Office Hub',
      rentPerSeat: 7500,
      location: 'Connaught Place',
      amenities: ['Cafeteria', 'Parking', 'Meeting Rooms'],
      rating: 4.2,
      similarityScore: 85
    },
    {
      propertyName: 'Executive Zone',
      rentPerSeat: 8000,
      location: 'Janpath',
      amenities: ['Cafeteria', 'Parking', 'Meeting Rooms'],
      rating: 4.6,
      similarityScore: 80
    }
  ],
  'Mumbai-Andheri East-Managed Office': [
    {
      propertyName: 'Enterprise Square',
      rentPerSeat: 1400,
      amenities: ['Dedicated Reception', '24/7 Access', 'Cafeteria'],
      rating: 4.7,
    },
    {
      propertyName: 'Business Bay',
      rentPerSeat: 1600,
      amenities: ['Premium Interiors', 'Parking', 'Meeting Rooms'],
      rating: 4.4,
    },
  ],
  'Bangalore-MG Road-Conventional Office': [
    {
      propertyName: 'Prestige Tower',
      rentPerSqFt: 95,
      amenities: ['Premium Location', 'Parking', '24/7 Access'],
      rating: 4.6,
    },
    {
      propertyName: 'Brigade Gardens',
      rentPerSqFt: 105,
      amenities: ['High-end Finishes', 'Security', 'Parking'],
      rating: 4.5,
    },
  ],
};

function PropertyDetailsCard({ property }) {
  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Property Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Property Name
            </Typography>
            <Typography variant="body1" gutterBottom>
              {property.propertyName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Property Type
            </Typography>
            <Typography variant="body1" gutterBottom>
              {property.propertyType}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Location
            </Typography>
            <Typography variant="body1" gutterBottom>
              {property.locality}, {property.city}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Agreement Period
            </Typography>
            <Typography variant="body1" gutterBottom>
              {moment(property.agreementStartDate).format('MMM D, YYYY')} - 
              {moment(property.agreementEndDate).format('MMM D, YYYY')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Operator/Landlord
            </Typography>
            <Typography variant="body1" gutterBottom>
              {property.operatorName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Security Deposit
            </Typography>
            <Typography variant="body1" gutterBottom>
              {formatIndianRupees(property.securityDeposit)}
            </Typography>
          </Grid>
          {(property.propertyType === 'Coworking' || property.propertyType === 'Managed Office') && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle2" color="text.secondary">
                  Billable Seats
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {property.billableSeats}
                </Typography>
              </Grid>
              {property.propertyType === 'Coworking' && (
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Rent per Seat
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {formatIndianRupees(property.rentPerSeat)}
                  </Typography>
                </Grid>
              )}
            </>
          )}
          {property.propertyType !== 'Coworking' && (
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle2" color="text.secondary">
                Size (sq.ft)
              </Typography>
              <Typography variant="body1" gutterBottom>
                {property.size}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Status
            </Typography>
            <Chip 
              label={property.status}
              color={property.status === 'Active' ? 'success' : 'default'}
              size="small"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function Benchmarking() {
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [loading, setLoading] = useState(false);
  const [comparisons, setComparisons] = useState([]);
  const [marketStats, setMarketStats] = useState(null);

  useEffect(() => {
    if (selectedProperty) {
      setLoading(true);
      // Simulate API call
      setTimeout(() => {
        const property = selectedProperty;
        const key = `${property.city}-${property.locality}-${property.propertyType}`;
        const marketProperties = marketData[key] || [];
        
        // Ensure we're working with fresh market data
        setComparisons(marketProperties);
        
        // Calculate market statistics based on property type
        if (marketProperties.length > 0) {
          if (property.propertyType === 'Conventional Office') {
            const avgRentPerSqFt = marketProperties.reduce((acc, curr) => acc + (curr.rentPerSqFt || 0), 0) / marketProperties.length;
            const rentPerSqFtDiff = ((property.rentPerSqFt - avgRentPerSqFt) / avgRentPerSqFt) * 100;
            
            setMarketStats({
              type: 'conventional',
              avgRentPerSqFt,
              rentPerSqFtDiff,
            });
          } else {
            // Force numbers for calculation
            const avgRentPerSeat = marketProperties.reduce((acc, curr) => acc + (Number(curr.rentPerSeat) || 0), 0) / marketProperties.length;
            const rentPerSeatDiff = ((Number(property.rentPerSeat) - avgRentPerSeat) / avgRentPerSeat) * 100;
            
            setMarketStats({
              type: 'flexible',
              avgRentPerSeat,
              rentPerSeatDiff,
            });
          }
        } else {
          setMarketStats(null);
        }
        
        setLoading(false);
      }, 1000);
    }
  }, [selectedProperty]);

  const handlePropertyChange = (event, newValue) => {
    setSelectedProperty(newValue);
    setMarketStats(null);
    setComparisons([]);
  };

  const getComparisonStatus = (diff) => {
    if (!diff && diff !== 0) return { label: 'No Data', color: 'default' };
    if (diff <= -10) return { label: 'Below Market', color: 'success' };
    if (diff >= 10) return { label: 'Above Market', color: 'error' };
    return { label: 'At Market', color: 'primary' };
  };

  const isConventionalOffice = selectedProperty?.propertyType === 'Conventional Office';

  const renderMarketPosition = () => {
    if (!marketStats) {
      return (
        <Grid item xs={12}>
          <Box sx={{ p: 3 }}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Market Position
              </Typography>
              <Box sx={{ textAlign: 'center', py: 3 }}>
                <Typography variant="body1" color="text.secondary">
                  Benchmarking data is not available for this property yet.
                </Typography>
              </Box>
            </Paper>
          </Box>
        </Grid>
      );
    }

    const diff = isConventionalOffice ? marketStats.rentPerSqFtDiff : marketStats.rentPerSeatDiff;
    const average = isConventionalOffice ? marketStats.avgRentPerSqFt : marketStats.avgRentPerSeat;
    const currentValue = isConventionalOffice ? selectedProperty.rentPerSqFt : selectedProperty.rentPerSeat;

    return (
      <Grid item xs={12}>
        <Box sx={{ p: 3 }}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Market Position
            </Typography>
            <Grid container spacing={2}>
              {selectedProperty.propertyType === 'Coworking' ? (
                <>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="subtitle1">Current Rent per Seat</Typography>
                        <Typography variant="h5">
                          {selectedProperty && formatIndianRupees(selectedProperty.rentPerSeat)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="subtitle1">Market Average Rent per Seat</Typography>
                        <Typography variant="h5">
                          {marketStats ? formatIndianRupees(marketStats.avgRentPerSeat) : 'N/A'}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="subtitle1">Market Position</Typography>
                        <Typography variant="h5" color={diff > 0 ? 'error.main' : diff < 0 ? 'success.main' : 'text.primary'}>
                          {diff ? `${diff > 0 ? '+' : ''}${diff.toFixed(1)}%` : 'N/A'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {diff > 0 ? 'Above' : diff < 0 ? 'Below' : 'At'} Market Average
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="subtitle1">Current Rent</Typography>
                        <Typography variant="h5">
                          {selectedProperty && formatIndianRupees(selectedProperty.rentPerMonth)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="subtitle1">Market Average Rent</Typography>
                        <Typography variant="h5">
                          {marketStats ? formatIndianRupees(average * (isConventionalOffice ? selectedProperty.size : selectedProperty.billableSeats)) : 'N/A'}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="subtitle1">Market Position</Typography>
                        <Typography variant="h5" color={diff > 0 ? 'error.main' : diff < 0 ? 'success.main' : 'text.primary'}>
                          {diff ? `${diff > 0 ? '+' : ''}${diff.toFixed(1)}%` : 'N/A'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {diff > 0 ? 'Above' : diff < 0 ? 'Below' : 'At'} Market Average
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        </Box>
      </Grid>
    );
  };

  return (
    <Container 
      maxWidth={false} 
      disableGutters
      sx={{ height: '100%' }}
    >
      <Paper 
        sx={{ 
          height: '100%',
          borderRadius: 0,
        }}
      >
        <Box sx={{ px: 3, pt: 3 }}>
          <Autocomplete
            options={sampleData}
            getOptionLabel={(option) => `${option.propertyName} - ${option.locality}, ${option.city}`}
            renderInput={(params) => (
              <TextField 
                {...params} 
                label="Search Properties" 
                variant="outlined" 
                fullWidth 
              />
            )}
            value={selectedProperty}
            onChange={(event, newValue) => {
              setSelectedProperty(newValue);
            }}
            renderOption={(props, option) => (
              <li {...props}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="body1">{option.propertyName}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {option.locality}, {option.city}
                  </Typography>
                </Box>
              </li>
            )}
          />
        </Box>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        )}

        {!loading && !selectedProperty && (
          <Box sx={{ p: 3, textAlign: 'center' }}>
            <Typography variant="body1">
              Select a property to view benchmarking details
            </Typography>
          </Box>
        )}

        {!loading && selectedProperty && (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box sx={{ p: 3 }}>
                <PropertyDetailsCard property={selectedProperty} />
              </Box>
            </Grid>

            {selectedProperty && renderMarketPosition()}

            {!loading && selectedProperty && comparisons && comparisons.length > 0 && (
              <Grid item xs={12}>
                <Box sx={{ p: 3 }}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Comparable Properties
                    </Typography>
                    {comparisons && comparisons.length > 0 ? (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Property</TableCell>
                              <TableCell>Location</TableCell>
                              {selectedProperty.propertyType === 'Coworking' ? (
                                <TableCell>Rent/Seat</TableCell>
                              ) : (
                                <TableCell>Rent/Sq.Ft</TableCell>
                              )}
                              <TableCell>Occupancy</TableCell>
                              <TableCell>Rating</TableCell>
                              <TableCell>Similarity Score</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {comparisons.map((comparison, index) => (
                              <TableRow key={index}>
                                <TableCell>{comparison.propertyName || 'N/A'}</TableCell>
                                <TableCell>{comparison.location || 'N/A'}</TableCell>
                                <TableCell>
                                  {selectedProperty.propertyType === 'Coworking'
                                    ? formatIndianRupees(comparison.rentPerSeat || 0)
                                    : `₹${comparison.rentPerSqFt || 'N/A'}`}
                                </TableCell>
                                <TableCell>{comparison.occupancyRate || 'N/A'}%</TableCell>
                                <TableCell>{comparison.rating || 'N/A'}</TableCell>
                                <TableCell>
                                  <Chip 
                                    label={`${comparison.similarityScore || 0}%`} 
                                    color="primary"
                                    size="small"
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Box sx={{ textAlign: 'center', py: 3 }}>
                        <Typography variant="body1" color="text.secondary">
                          Benchmarking data is not available for this property yet.
                        </Typography>
                      </Box>
                    )}
                  </Paper>
                </Box>
              </Grid>
            )}

            {!loading && selectedProperty && selectedProperty.rentalHistory && selectedProperty.rentalHistory.length > 0 && (
              <Grid item xs={12}>
                <Box sx={{ p: 3 }}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Rental History
                    </Typography>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Tenant</TableCell>
                            <TableCell>Lease Period</TableCell>
                            <TableCell>Monthly Rent</TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedProperty.rentalHistory.map((rental, index) => (
                            <TableRow key={index}>
                              <TableCell>{rental.tenant || 'N/A'}</TableCell>
                              <TableCell>{rental.leasePeriod || 'N/A'}</TableCell>
                              <TableCell>₹{rental.monthlyRent ? rental.monthlyRent.toLocaleString() : 'N/A'}</TableCell>
                              <TableCell>
                                <Chip 
                                  label={rental.status || 'N/A'} 
                                  color={
                                    rental.status === 'Active' ? 'success' : 
                                    rental.status === 'Expired' ? 'default' : 'warning'
                                  } 
                                  size="small" 
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </Paper>
    </Container>
  );
}

export default Benchmarking;
