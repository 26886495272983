import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  properties: [],
  loading: false,
  error: null,
  selectedProperty: null,
  editDialogOpen: false,
  isNewProperty: true
};

const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    setProperties: (state, action) => {
      state.properties = action.payload;
      state.loading = false;
      state.error = null;
    },
    addProperty: (state, action) => {
      state.properties.push(action.payload);
    },
    updateProperty: (state, action) => {
      const index = state.properties.findIndex(p => p.id === action.payload.id);
      if (index !== -1) {
        state.properties[index] = action.payload;
      }
    },
    deleteProperty: (state, action) => {
      state.properties = state.properties.filter(p => p.id !== action.payload);
    },
    setSelectedProperty: (state, action) => {
      state.selectedProperty = action.payload;
      state.editDialogOpen = true;
      state.isNewProperty = false;
    },
    clearSelectedProperty: (state) => {
      state.selectedProperty = null;
      state.editDialogOpen = false;
      state.isNewProperty = true;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setEditDialogOpen: (state, action) => {
      state.editDialogOpen = action.payload;
      if (!action.payload) {
        state.selectedProperty = null;
        state.isNewProperty = true;
      }
    }
  }
});

export const {
  setProperties,
  addProperty,
  updateProperty,
  deleteProperty,
  setSelectedProperty,
  clearSelectedProperty,
  setLoading,
  setError,
  setEditDialogOpen
} = propertySlice.actions;

export default propertySlice.reducer;
