import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const ImportAgreementDialog = ({ open, onClose, onImport }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.type !== 'application/pdf') {
      setError('Please select a PDF file');
      return;
    }
    setSelectedFile(file);
    setError(null);
  };

  const handleImport = async () => {
    if (!selectedFile) {
      setError('Please select a file first');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const formData = new FormData();
      formData.append('file', selectedFile);
      const baseURL = "https://us-central1-gofloaters-property-manager.cloudfunctions.net/api"
      const response = await fetch(`${baseURL}/properties/extract-info`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to extract information from the document');
      }

      const data = await response.json();
      if (!data.success) {
        throw new Error(data.error || 'Failed to process document');
      }

      // Calculate monthlyMembershipFees if null
      let monthlyMembershipFees = data.data.monthlyMembershipFees;
      if (monthlyMembershipFees === null) {
        if (data.data.totalMonthlyRent) {
          monthlyMembershipFees = data.data.totalMonthlyRent;
        } else if (data.data.rentPerSeat && data.data.billableSeats) {
          monthlyMembershipFees = data.data.rentPerSeat * data.data.billableSeats;
        }
      }

      // Calculate securityDeposit if null and securityDepositMonths exists
      let securityDeposit = data.data.securityDeposit;
      if (securityDeposit === null && data.data.securityDepositMonths && monthlyMembershipFees) {
        securityDeposit = data.data.securityDepositMonths * monthlyMembershipFees;
      }

      // Map the extracted data to property form fields
      const propertyData = {
        propertyName: data.data.propertyName,
        propertyType: 'coworking', // Default to coworking since it's from agreement
        city: data.data.city,
        locality: data.data.locality,
        rentPerMonth: monthlyMembershipFees,
        securityDeposit: securityDeposit,
        agreementStartDate: data.data.agreementStartDate,
        agreementTerm: data.data.agreementTerm,
        agreementEndDate: data.data.agreementEndDate,
        operatorName: data.data.operatorName,
        billableSeats: data.data.billableSeats,
        status: 'active',
        noticePeriod: data.data.noticePeriod,
        lockinPeriod: data.data.lockinPeriod,
        rentalEscalation: data.data.rentalEscalation,
        securityDepositMonths: data.data.securityDepositMonths,
        complimentaryBikeParking: data.data.complimentaryBikeParking,
        complimentaryCarParking: data.data.complimentaryCarParking,
        carParkCost: data.data.carParkCost,
        bikeParkCost: data.data.bikeParkCost,
        centerTimings: data.data.centerTimings,
        agreementStorageUrl: data.data.storageUrl,
        agreementFileName: selectedFile.name
      };

      onImport(propertyData);
      handleClose();
    } catch (err) {
      console.error('Error importing document:', err);
      setError(err.message || 'Failed to import document');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setSelectedFile(null);
    setError(null);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Import Agreement</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 3,
            border: '2px dashed #ccc',
            borderRadius: 1,
            backgroundColor: '#fafafa',
            cursor: 'pointer',
          }}
          component="label"
        >
          <input
            type="file"
            accept=".pdf"
            hidden
            onChange={handleFileSelect}
          />
          <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 1 }} />
          <Typography variant="h6" component="div" gutterBottom>
            Drop PDF file here or click to browse
          </Typography>
          {selectedFile && (
            <Typography variant="body2" color="textSecondary">
              Selected: {selectedFile.name}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleImport}
          disabled={!selectedFile || loading}
          variant="contained"
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          {loading ? 'Processing...' : 'Extract Information'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportAgreementDialog;
