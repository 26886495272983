import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  IconButton,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import {
  DownloadOutlined as DownloadIcon,
  PrintOutlined as PrintIcon,
  ShareOutlined as ShareIcon,
  TrendingUp as TrendingUpIcon,
} from '@mui/icons-material';
import { LineChart } from '@mui/x-charts/LineChart';

// Placeholder data for portfolio performance
const performanceData = [
  { month: 'Jan', totalValue: 3200000, rentIncome: 42000, appreciation: 50000 },
  { month: 'Feb', totalValue: 3250000, rentIncome: 41500, appreciation: 55000 },
  { month: 'Mar', totalValue: 3300000, rentIncome: 43000, appreciation: 60000 },
  { month: 'Apr', totalValue: 3350000, rentIncome: 42500, appreciation: 65000 },
  { month: 'May', totalValue: 3400000, rentIncome: 44000, appreciation: 70000 },
  { month: 'Jun', totalValue: 3450000, rentIncome: 43500, appreciation: 75000 },
];

const performanceSummary = {
  totalInvestment: 10000000,
  currentPortfolioValue: 11500000,
  totalRentIncome: 260000,
  totalAppreciation: 375000,
  overallReturn: 15.75,
};

function PortfolioPerformance() {
  const [timeframe, setTimeframe] = useState('6months');
  const [metric, setMetric] = useState('totalValue');

  const handleDownload = () => {
    // Placeholder for download functionality
    console.log('Downloading portfolio performance report');
  };

  const handlePrint = () => {
    // Placeholder for print functionality
    window.print();
  };

  const handleShare = () => {
    // Placeholder for share functionality
    console.log('Sharing portfolio performance report');
  };

  // Prepare data for chart
  const chartData = {
    xAxis: [{ data: performanceData.map(item => item.month) }],
    series: [
      {
        data: performanceData.map(item => item[metric]),
        label: metric.charAt(0).toUpperCase() + metric.slice(1),
      },
    ],
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Portfolio Performance
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button 
              variant="outlined" 
              startIcon={<DownloadIcon />} 
              onClick={handleDownload}
            >
              Download
            </Button>
            <Button 
              variant="outlined" 
              startIcon={<PrintIcon />} 
              onClick={handlePrint}
            >
              Print
            </Button>
            <Button 
              variant="outlined" 
              startIcon={<ShareIcon />} 
              onClick={handleShare}
            >
              Share
            </Button>
          </Box>
        </Box>

        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Paper variant="outlined" sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Performance Metrics
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    Total Investment
                  </Typography>
                  <Typography variant="h6">
                    ₹{performanceSummary.totalInvestment.toLocaleString()}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    Current Portfolio Value
                  </Typography>
                  <Typography variant="h6" color="success.main">
                    ₹{performanceSummary.currentPortfolioValue.toLocaleString()}
                    <Box component="span" sx={{ ml: 1, fontSize: '0.7em', color: 'success.main' }}>
                      <TrendingUpIcon sx={{ fontSize: 'inherit', verticalAlign: 'middle', mr: 0.5 }} />
                      15.75%
                    </Box>
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    Total Rent Income
                  </Typography>
                  <Typography variant="h6">
                    ₹{performanceSummary.totalRentIncome.toLocaleString()}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    Total Appreciation
                  </Typography>
                  <Typography variant="h6" color="success.main">
                    ₹{performanceSummary.totalAppreciation.toLocaleString()}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper variant="outlined" sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">
                  Performance Trend
                </Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <FormControl size="small" sx={{ minWidth: 120 }}>
                    <InputLabel>Timeframe</InputLabel>
                    <Select
                      value={timeframe}
                      label="Timeframe"
                      onChange={(e) => setTimeframe(e.target.value)}
                    >
                      <MenuItem value="6months">Last 6 Months</MenuItem>
                      <MenuItem value="1year">Last 1 Year</MenuItem>
                      <MenuItem value="2years">Last 2 Years</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl size="small" sx={{ minWidth: 120 }}>
                    <InputLabel>Metric</InputLabel>
                    <Select
                      value={metric}
                      label="Metric"
                      onChange={(e) => setMetric(e.target.value)}
                    >
                      <MenuItem value="totalValue">Total Value</MenuItem>
                      <MenuItem value="rentIncome">Rent Income</MenuItem>
                      <MenuItem value="appreciation">Appreciation</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box sx={{ width: '100%', height: 300 }}>
                <LineChart
                  xAxis={chartData.xAxis}
                  series={chartData.series}
                  height={300}
                  margin={{
                    left: 50,
                    right: 50,
                  }}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default PortfolioPerformance;
