import { GoogleGenerativeAI } from "@google/generative-ai";

let genAI = null;

export const initializeGemini = (apiKey) => {
  genAI = new GoogleGenerativeAI(apiKey);
};

/*
const formatMarkdownTable = (properties) => {
  // Group properties by city and sum billable seats
  const citySeats = properties.reduce((acc, p) => {
    acc[p.city] = (acc[p.city] || 0) + parseInt(p.billableSeats || 0);
    return acc;
  }, {});

  // Create table header with proper spacing
  let table = '| City | Total Billable Seats |\n';
  table += '|------|--------------------|\n';
  
  // Add rows with consistent spacing
  Object.entries(citySeats).forEach(([city, seats]) => {
    table += `| ${city.padEnd(4)} | ${seats.toString().padStart(18)} |\n`;
  });
  
  return table;
};
*/

export const generateResponse = async (message, propertyContext) => {
  if (!genAI) {
    throw new Error('Gemini AI not initialized. Please call initializeGemini with your API key first.');
  }

  const model = genAI.getGenerativeModel({ model: "gemini-pro" });

  // Format property data for better context
  const formattedProperties = propertyContext.properties.map(p => `
    Property: ${p.propertyName}
    
    Location Details:
    - City: ${p.city}
    - Locality: ${p.locality}
    - Center Timings: ${p.centerTimings}
    
    Property Information:
    - Type: ${p.propertyType}
    - Status: ${p.status}
    - Operator: ${p.operatorName}
    
    Agreement Details:
    - Start Date: ${p.agreementStartDate}
    - End Date: ${p.agreementEndDate}
    - Term (months): ${p.agreementTerm}
    - Lock-in Period (months): ${p.lockinPeriod}
    - Notice Period (months): ${p.noticePeriod}
    
    Financial Details:
    - Rent per Month: ₹${p.rentPerMonth}
    - Security Deposit: ₹${p.securityDeposit}
    - Security Deposit Months: ${p.securityDepositMonths}
    - Rental Escalation (%): ${p.rentalEscalation}
    
    Capacity & Parking:
    - Billable Seats: ${p.billableSeats}
    - Car Parking Cost: ₹${p.carParkCost}
    - Bike Parking Cost: ₹${p.bikeParkCost}
    - Complimentary Car Parking: ${p.complimentaryCarParking}
    - Complimentary Bike Parking: ${p.complimentaryBikeParking}
  `).join('\n\n');

  // Pre-format common tables
  // const propertiesByCity = formatMarkdownTable(propertyContext.properties);

  const prompt = `
    You are an AI assistant for a property management system. You have access to the following properties with detailed information:

    ${formattedProperties}

    Instructions for Response Format:
    1. Reponse in simple text paragraph or lists. Use markdown format where appropriate. 
    2. Use headers (##) for main sections
    3. Use bullet points (*) for listing features or points
    4. Use bold (**) for important values or highlights
    5. For financial figures, format as: ₹XX,XXX
    6. For dates, use consistent format: YYYY-MM-DD
    7. For property status or types, use appropriate formatting:
       - \`Active\` for status
       - *Coworking* for types
    8. For long responses, use clear section breaks with headers
    9. NEVER return malformed tables or lists
    10. Use code blocks (\`\`) for specific identifiers or reference numbers

    Additional Instructions:
    1. Answer questions about the properties listed above using all available information
    2. For financial queries, provide exact numbers and calculations
    3. For dates, consider agreement terms, lock-in periods, and notice periods
    4. Help analyze parking requirements and costs
    5. Track document availability and types
    6. Monitor agreement status and important dates
    7. If asked about information not available in the context, politely indicate that
    8. Keep responses concise and professional
    9. For property recommendations, consider type, status, location, and financial aspects
    10. If multiple properties match a query, use the exact table format shown above

    User query: ${message}
  `;

  try {
    const result = await model.generateContent(prompt);
    const response = await result.response;
    let text = response.text();

    
    return text;
  } catch (error) {
    console.error('Error generating Gemini response:', error);
    throw error;
  }
};
