import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  IconButton,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  LinearProgress,
} from '@mui/material';
import {
  DownloadOutlined as DownloadIcon,
  PrintOutlined as PrintIcon,
  ShareOutlined as ShareIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
} from '@mui/icons-material';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';

function PortfolioSummary() {
  const [loading, setLoading] = useState(true);
  const [portfolioData, setPortfolioData] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [portfolioMetrics, setPortfolioMetrics] = useState({
    totalValue: 0,
    totalRent: 0,
    averageMonthlyRent: 0,
    rentYTD: 0,
    totalProperties: 0,
    activeLeases: 0,
    inactiveLeases: 0,
    renewalsThisQuarter: 0,
    renewalsNextQuarter: 0
  });

  const { properties } = useSelector((state) => state.property);
  const { organizationId } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchPortfolioData = async () => {
      try {
        const propertyData = [];
        let totalValue = 0;
        let totalRent = 0;
        let activeLeases = 0;
        let inactiveLeases = 0;
        let renewalsThisQuarter = 0;
        let renewalsNextQuarter = 0;
        let rentYTD = 0;

        // Get current quarter start and end
        const now = moment();
        const currentQuarterStart = moment(now).startOf('quarter');
        const currentQuarterEnd = moment(now).endOf('quarter');
        const nextQuarterStart = moment(now).add(1, 'quarter').startOf('quarter');
        const nextQuarterEnd = moment(now).add(1, 'quarter').endOf('quarter');

        // Fetch rent data for each property
        const propertyPromises = properties.map(async property => {
          const rentQuery = collection(db, 'properties', property.id, 'payments');
          const rentSnapshot = await getDocs(rentQuery);
          
          // Calculate annual rent for the property
          const annualRent = rentSnapshot.docs.reduce((total, doc) => {
            const payment = doc.data();
            const paymentDate = moment(payment.dueDate.toDate());
            if (paymentDate.isAfter(moment().subtract(12, 'months'))) {
              return total + (payment.amount || 0);
            }
            return total;
          }, 0);

          // Fetch and calculate annual expenses
          const expensesQuery = collection(db, 'properties', property.id, 'expenses');
          const expensesSnapshot = await getDocs(expensesQuery);
          const annualExpenses = expensesSnapshot.docs.reduce((total, doc) => {
            const expense = doc.data();
            const expenseDate = moment(expense.date.toDate());
            
            // For one-time expenses, only include if they occurred in the last 12 months
            if (expense.frequency === 'One Time') {
              if (expenseDate.isAfter(moment().subtract(12, 'months'))) {
                return total + (expense.amount || 0);
              }
              return total;
            }
            
            // For monthly expenses, multiply by 12 to get annual amount
            if (expense.frequency === 'Monthly') {
              // Only include if the expense is currently active
              if (expenseDate.isBefore(moment()) && (!expense.endDate || moment(expense.endDate.toDate()).isAfter(moment()))) {
                return total + ((expense.amount || 0) * 12);
              }
            }
            
            return total;
          }, 0);

          // Calculate YTD rent
          const propertyRentYTD = rentSnapshot.docs.reduce((total, doc) => {
            const payment = doc.data();
            const paymentDate = moment(payment.dueDate.toDate());
            if (paymentDate.isAfter(moment().startOf('year')) && paymentDate.isBefore(now)) {
              return total + (payment.amount || 0);
            }
            return total;
          }, 0);

          // Calculate occupancy
          const occupied = property.units?.filter(unit => unit.status === 'occupied').length || 0;
          const total = property.units?.length || 1;
          const occupancyRate = (occupied / total) * 100;

          // Calculate NPV
          const discountRate = 0.10; // 10% discount rate
          const endDate = moment(property.agreementEndDate);
          const yearsRemaining = endDate.diff(now, 'years', true);
          
          // NPV = Σ (CF_t / (1 + r)^t) where CF_t is cash flow at time t, r is discount rate
          let npv = 0;
          if (yearsRemaining > 0) {
            for (let t = 1; t <= Math.ceil(yearsRemaining); t++) {
              // For the last year, only count the proportional rent
              const yearRent = t === Math.ceil(yearsRemaining) 
                ? annualRent * (yearsRemaining % 1 || 1)
                : annualRent;
              npv += yearRent / Math.pow(1 + discountRate, t);
            }
          }
          npv = Math.round(npv);

          // Calculate cost per employee including expenses
          const billableSeats = property.billableSeats || 1; // Default to 1 to avoid division by zero
          const totalAnnualCost = annualRent + annualExpenses;
          const costPerEmployee = Math.round(totalAnnualCost / billableSeats);

          // Check lease status
          const agreementStartDate = property.agreementStartDate ? 
            (property.agreementStartDate.toDate ? moment(property.agreementStartDate.toDate()) : moment(property.agreementStartDate)) 
            : null;
          const agreementEndDate = property.agreementEndDate ? 
            (property.agreementEndDate.toDate ? moment(property.agreementEndDate.toDate()) : moment(property.agreementEndDate)) 
            : null;
          
          console.log('Property:', property.propertyName);
          console.log('Raw Start Date:', property.agreementStartDate);
          console.log('Raw End Date:', property.agreementEndDate);
          console.log('Parsed Start Date:', agreementStartDate ? agreementStartDate.format() : 'null');
          console.log('Parsed End Date:', agreementEndDate ? agreementEndDate.format() : 'null');
          console.log('Current Time:', now.format());
          
          const isActive = agreementStartDate && agreementEndDate ? 
            now.isBetween(agreementStartDate, agreementEndDate, 'day', '[]') : false;
          
          console.log('Is Active:', isActive);
          
          if (isActive) {
            activeLeases++;
            // Check if agreement expires in current quarter
            if (agreementEndDate.isBetween(currentQuarterStart, currentQuarterEnd)) {
              renewalsThisQuarter++;
            }
            // Check if agreement expires in next quarter
            else if (agreementEndDate.isBetween(nextQuarterStart, nextQuarterEnd)) {
              renewalsNextQuarter++;
            }
          } else {
            inactiveLeases++;
          }

          console.log('Active Leases Count:', activeLeases);
          console.log('Inactive Leases Count:', inactiveLeases);
          console.log('------------------------');

          const propertyMetrics = {
            id: property.id,
            propertyName: property.propertyName,
            location: `${property.city}, ${property.state}`,
            purchasePrice: property.purchasePrice || 0,
            currentValuation: property.currentValuation || property.purchasePrice || 0,
            annualRent: annualRent,
            annualExpenses: annualExpenses,
            propertyType: property.propertyType,
            agreementEndDate: agreementEndDate,
            npv: npv,
            costPerEmployee: costPerEmployee,
            billableSeats: billableSeats
          };

          propertyData.push(propertyMetrics);
          totalValue += npv; // Use NPV instead of current valuation
          totalRent += annualRent;
          totalRent = Math.round(totalRent);
          rentYTD += propertyRentYTD;
          
          return propertyMetrics;
        });

        await Promise.all(propertyPromises);

        setPortfolioData(propertyData);
        setPortfolioMetrics({
          totalValue,
          totalRent,
          averageMonthlyRent: Math.round(totalRent / (12 * properties.length)),
          rentYTD: Math.round(rentYTD),
          totalProperties: properties.length,
          activeLeases,
          inactiveLeases,
          renewalsThisQuarter,
          renewalsNextQuarter
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching portfolio data:', error);
        setLoading(false);
      }
    };

    if (properties.length > 0) {
      fetchPortfolioData();
    }
  }, [properties, organizationId]);

  const handleDownload = () => {
    // TODO: Implement CSV download
    console.log('Downloading portfolio summary');
  };

  const handlePrint = () => {
    window.print();
  };

  const handleShare = () => {
    // TODO: Implement share functionality
    console.log('Sharing portfolio summary');
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <LinearProgress />
      </Container>
    );
  }

  // Calculate average cost per employee
  const averageCostPerEmployee = Math.round(
    portfolioData.reduce((sum, property) => sum + (property.costPerEmployee || 0), 0) / 
    portfolioData.length
  );

  return (
      <Container sx={{ p: 1 }}>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button 
              variant="outlined" 
              startIcon={<DownloadIcon />} 
              onClick={handleDownload}
            >
              Download
            </Button>
            <Button 
              variant="outlined" 
              startIcon={<PrintIcon />} 
              onClick={handlePrint}
            >
              Print
            </Button>
            <Button 
              variant="outlined" 
              startIcon={<ShareIcon />} 
              onClick={handleShare}
            >
              Share
            </Button>
          </Box>
        </Box>

        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  NPV
                </Typography>
                <Typography variant="h5">
                  ₹{portfolioMetrics.totalValue.toLocaleString()}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Annual Rental
                </Typography>
                <Typography variant="h5">
                  ₹{portfolioMetrics.totalRent.toLocaleString()}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Average Monthly Rent
                </Typography>
                <Typography variant="h5">
                  ₹{portfolioMetrics.averageMonthlyRent.toLocaleString()}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Rent YTD
                </Typography>
                <Typography variant="h5">
                  ₹{portfolioMetrics.rentYTD.toLocaleString()}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Active Leases
                </Typography>
                <Typography variant="h5">
                  {portfolioMetrics.activeLeases}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Inactive Leases
                </Typography>
                <Typography variant="h5">
                  {portfolioMetrics.inactiveLeases}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Renewals This Quarter
                </Typography>
                <Typography variant="h5">
                  {portfolioMetrics.renewalsThisQuarter}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Renewals Next Quarter
                </Typography>
                <Typography variant="h5">
                  {portfolioMetrics.renewalsNextQuarter}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Net Present Value by Property
                </Typography>
                <Box sx={{ width: '100%', height: 500, pb: 2 }}>
                  <BarChart
                    xAxis={[{
                      scaleType: 'band',
                      data: portfolioData.map(property => property.propertyName),
                      tickLabelStyle: {
                        angle: 45,
                        textAnchor: 'start',
                        fontSize: 12
                      }
                    }]}
                    yAxis={[{
                      tickMinStep: 2000000,
                      valueFormatter: (value) => `${(value/1000000).toFixed(0)}`,
                      label: 'NPV (₹ Millions)'
                    }]}
                    series={[{
                      data: portfolioData.map(property => property.npv),
                      color: '#4DB6AC',
                      label: 'NPV (₹)',
                      valueFormatter: (value) => `₹${value.toLocaleString()}`
                    }]}
                    height={450}
                    margin={{ left: 80, right: 30, top: 40, bottom: 100 }}
                    sx={{
                      '.MuiBarElement-root:hover': {
                        filter: 'brightness(0.9)'
                      }
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Annual Cost per Employee
                </Typography>
                <Box sx={{ width: '100%', height: 500, pb: 2 }}>
                  <LineChart
                    xAxis={[{
                      scaleType: 'band',
                      data: portfolioData.map(property => property.propertyName || ''),
                      tickLabelStyle: {
                        angle: 45,
                        textAnchor: 'start',
                        fontSize: 12
                      }
                    }]}
                    yAxis={[{
                      min: 0,
                      max: 1000000,
                      tickCount: 6,
                      label: 'Annual Cost per Employee (₹)'
                    }]}
                    series={[
                      {
                        data: portfolioData.map(property => property.costPerEmployee || 0),
                        color: '#4DB6AC',
                        label: 'Annual Cost per Employee',
                        valueFormatter: (value) => `₹${(value || 0).toLocaleString()}`,
                        curve: "linear",
                        showMark: true,
                        area: false,
                        connectNulls: true
                      },
                      {
                        data: portfolioData.map(() => averageCostPerEmployee),
                        color: '#a5a5a5',
                        label: 'Average',
                        valueFormatter: (value) => `Average: ₹${(value || 0).toLocaleString()}`,
                        curve: "linear",
                        showMark: false,
                        area: false,
                        connectNulls: true,
                        lineStyle: {
                          strokeDasharray: '5 5'
                        }
                      }
                    ]}
                    height={450}
                    margin={{ left: 120, right: 30, top: 40, bottom: 100 }}
                    sx={{
                      '.MuiChartsAxis-line': {
                        stroke: '#000',
                        strokeWidth: 1
                      },
                      '.MuiChartsAxis-tick': {
                        stroke: '#000',
                        strokeWidth: 1
                      },
                      '.MuiChartsAxis-label': {
                        fill: '#000',
                        fontWeight: 400
                      }
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Divider sx={{ mb: 3 }} />
      </Container>
  );
}

export default PortfolioSummary;
