import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Avatar,
} from '@mui/material';
import {
  SmartToy as AiIcon,
  Close as CloseIcon,
  Send as SendIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import { generateResponse, initializeGemini } from '../services/geminiService';

function ChatButton() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([
    { text: 'Hello! I\'m your AI assistant. How can I help you with your properties today?', sender: 'ai', timestamp: new Date() }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  
  const { properties } = useSelector((state) => state.property);
  
  useEffect(() => {
    const GEMINI_API_KEY = process.env.REACT_APP_GEMINI_API_KEY;
    if (GEMINI_API_KEY) {
      initializeGemini(GEMINI_API_KEY);
    }
  }, []);

  const handleSend = async () => {
    if (message.trim()) {
      const userMessage = { text: message, sender: 'user', timestamp: new Date() };
      setChatMessages(prev => [...prev, userMessage]);
      setMessage('');
      setIsLoading(true);

      try {
        if (!properties || properties.length === 0) {
          setChatMessages(prev => [...prev, {
            text: 'I don\'t have access to any property information at the moment. Please try again later when properties are loaded.',
            sender: 'ai',
            timestamp: new Date()
          }]);
          return;
        }

        const propertyContext = {
          properties: properties
        };

        const aiResponse = await generateResponse(message, propertyContext);
        
        setChatMessages(prev => [...prev, {
          text: aiResponse,
          sender: 'ai',
          timestamp: new Date()
        }]);
      } catch (error) {
        console.error('Error getting AI response:', error);
        setChatMessages(prev => [...prev, {
          text: 'I apologize, but I encountered an error. Please try again.',
          sender: 'ai',
          timestamp: new Date()
        }]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const renderMessageContent = (text) => {
    return (
      <Box sx={{ 
        width: '100%',
        '& table': {
          borderCollapse: 'collapse',
          width: '100%',
          margin: '16px 0',
          fontSize: '1rem',
          backgroundColor: '#ffffff',
        },
        '& th': {
          border: '1px solid rgba(224, 224, 224, 1)',
          padding: '12px 16px',
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          fontWeight: 'bold',
          textAlign: 'left',
          whiteSpace: 'nowrap',
        },
        '& td': {
          border: '1px solid rgba(224, 224, 224, 1)',
          padding: '12px 16px',
          textAlign: 'left',
          whiteSpace: 'nowrap',
        },
        '& p': { 
          margin: '12px 0', 
          fontSize: '1rem',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
        },
        '& ul, & ol': { 
          margin: '12px 0', 
          paddingLeft: '24px' 
        },
        '& pre': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          padding: '12px',
          borderRadius: '4px',
          overflowX: 'auto',
          margin: '12px 0',
        },
        '& code': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          padding: '3px 6px',
          borderRadius: '4px',
          fontSize: '0.9em',
          fontFamily: 'monospace',
        },
        overflowX: 'auto',
        overflowY: 'hidden'
      }}>
        <ReactMarkdown components={{
          table: ({node, ...props}) => (
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <table {...props} style={{ minWidth: 'max-content' }} />
            </div>
          ),
          th: ({node, ...props}) => (
            <th {...props} style={{ 
              border: '1px solid rgba(224, 224, 224, 1)',
              padding: '12px 16px',
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              fontWeight: 'bold',
              textAlign: 'left',
              whiteSpace: 'nowrap',
            }} />
          ),
          td: ({node, ...props}) => (
            <td {...props} style={{ 
              border: '1px solid rgba(224, 224, 224, 1)',
              padding: '12px 16px',
              textAlign: 'left',
              whiteSpace: 'nowrap',
            }} />
          ),
          p: ({node, children}) => (
            <Typography 
              variant="body1" 
              component="p" 
              sx={{ 
                my: 1.5,
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
              }}
            >
              {children}
            </Typography>
          ),
          code: ({node, inline, children}) => (
            inline ? 
              <code style={{
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                padding: '3px 6px',
                borderRadius: '4px',
                fontSize: '0.9em',
                fontFamily: 'monospace',
              }}>{children}</code> :
              <pre style={{
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                padding: '12px',
                borderRadius: '4px',
                overflowX: 'auto',
                margin: '12px 0',
                fontFamily: 'monospace',
              }}><code>{children}</code></pre>
          )
        }}>
          {text}
        </ReactMarkdown>
      </Box>
    );
  };

  return (
    <>
      <Fab
        color="primary"
        aria-label="ai-chat"
        onClick={() => setOpen(true)}
        sx={{
          position: 'fixed',
          bottom: 24,
          right: 24,
          zIndex: 1000,
        }}
      >
        <AiIcon />
      </Fab>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            height: '80vh',
            maxHeight: 700,
            minWidth: '800px',
            borderRadius: 2,
            backgroundColor: '#f8f9fa',
          }
        }}
      >
        <DialogTitle sx={{ 
          p: 2, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: 'divider',
          bgcolor: 'white',
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Avatar sx={{ bgcolor: 'primary.main' }}>
              <AiIcon />
            </Avatar>
            <Typography variant="h6">Property Assistant</Typography>
          </Box>
          <IconButton onClick={() => setOpen(false)} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent 
          sx={{ 
            p: 2, 
            display: 'flex', 
            flexDirection: 'column',
            bgcolor: '#f8f9fa',
          }}
        >
          <Box sx={{ 
            flexGrow: 1, 
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
            mb: 2,
            px: 1.5,
          }}>
            {chatMessages.map((msg, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: msg.sender === 'user' ? 'row-reverse' : 'row',
                  gap: 1,
                }}
              >
                <Avatar 
                  sx={{ 
                    bgcolor: msg.sender === 'user' ? 'secondary.main' : 'primary.main',
                    width: 32,
                    height: 32,
                  }}
                >
                  {msg.sender === 'user' ? <PersonIcon /> : <AiIcon />}
                </Avatar>
                <Box
                  sx={{
                    maxWidth: '75%',
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      p: 1.5,
                      backgroundColor: msg.sender === 'user' ? 'primary.main' : 'white',
                      color: msg.sender === 'user' ? 'white' : 'text.primary',
                      borderRadius: 1.5,
                      position: 'relative',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 8,
                        [msg.sender === 'user' ? 'right' : 'left']: -6,
                        borderStyle: 'solid',
                        borderWidth: '6px 6px 6px 0',
                        borderColor: `transparent ${msg.sender === 'user' ? '#111827' : 'white'} transparent transparent`,
                        transform: msg.sender === 'user' ? 'rotate(180deg)' : 'none',
                      }
                    }}
                  >
                    {renderMessageContent(msg.text)}
                  </Paper>
                </Box>
              </Box>
            ))}
            {isLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                <CircularProgress size={24} />
              </Box>
            )}
          </Box>
        </DialogContent>

        <DialogActions sx={{ 
          p: 2,
          borderTop: 1,
          borderColor: 'divider',
          bgcolor: 'white',
        }}>
          <Box sx={{ display: 'flex', width: '100%', gap: 1 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Ask me anything about your properties..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSend();
                }
              }}
              disabled={isLoading}
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#f8f9fa',
                }
              }}
            />
            <Button
              variant="contained"
              onClick={handleSend}
              disabled={!message.trim() || isLoading}
              endIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <SendIcon />}
            >
              Send
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ChatButton;
