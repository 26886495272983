import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Switch,
  FormControlLabel,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { updateSettings } from '../store/slices/settingsSlice';

function Settings() {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const [localSettings, setLocalSettings] = useState(settings);

  useEffect(() => {
    setLocalSettings(settings);
  }, [settings]);

  const handleSwitchChange = (setting) => (event) => {
    const newSettings = {
      ...localSettings,
      [setting]: event.target.checked,
    };
    setLocalSettings(newSettings);
    dispatch(updateSettings(newSettings));
  };

  const handleSelectChange = (setting) => (event) => {
    const newSettings = {
      ...localSettings,
      [setting]: event.target.value,
    };
    setLocalSettings(newSettings);
    dispatch(updateSettings(newSettings));
  };

  const SettingItem = ({ label, checked, onChange }) => (
    <ListItem>
      <ListItemText primary={label} />
      <ListItemSecondaryAction>
        <Switch
          edge="end"
          checked={checked}
          onChange={onChange}
          color="primary"
        />
      </ListItemSecondaryAction>
    </ListItem>
  );

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Recommendations Section */}
          <Grid item xs={12}>
            <Paper elevation={0} variant="outlined">
              <Box sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Recommendations
                </Typography>
                <List>
                  <SettingItem
                    label="Show renewal recommendations"
                    checked={localSettings.showRenewalRecommendations}
                    onChange={handleSwitchChange('showRenewalRecommendations')}
                  />
                  <SettingItem
                    label="Show space optimization recommendations"
                    checked={localSettings.showSpaceOptimizations}
                    onChange={handleSwitchChange('showSpaceOptimizations')}
                  />
                  <SettingItem
                    label="Enable AI recommendations"
                    checked={localSettings.enableAIRecommendations}
                    onChange={handleSwitchChange('enableAIRecommendations')}
                  />
                </List>
              </Box>
            </Paper>
          </Grid>

          {/* Notifications Section */}
          <Grid item xs={12}>
            <Paper elevation={0} variant="outlined">
              <Box sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Notifications
                </Typography>
                <List>
                  <SettingItem
                    label="Send payment reminders by email"
                    checked={localSettings.emailPaymentReminders}
                    onChange={handleSwitchChange('emailPaymentReminders')}
                  />
                  <SettingItem
                    label="Show reminders in the app"
                    checked={localSettings.inAppReminders}
                    onChange={handleSwitchChange('inAppReminders')}
                  />
                  <SettingItem
                    label="Email reminders for recommendations"
                    checked={localSettings.emailRecommendations}
                    onChange={handleSwitchChange('emailRecommendations')}
                  />
                </List>
              </Box>
            </Paper>
          </Grid>

          {/* Benchmarking Section */}
          <Grid item xs={12}>
            <Paper elevation={0} variant="outlined">
              <Box sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Benchmarking
                </Typography>
                <List>
                  <ListItem>
                    <ListItemText primary="Benchmark Frequency" />
                    <ListItemSecondaryAction>
                      <FormControl sx={{ minWidth: 150 }}>
                        <Select
                          value={localSettings.benchmarkFrequency}
                          onChange={handleSelectChange('benchmarkFrequency')}
                          variant="outlined"
                          size="small"
                        >
                          <MenuItem value="realtime">Realtime</MenuItem>
                          <MenuItem value="weekly">Weekly</MenuItem>
                          <MenuItem value="monthly">Monthly</MenuItem>
                        </Select>
                      </FormControl>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Box>
            </Paper>
          </Grid>

          {/* Industry Reports Section */}
          <Grid item xs={12}>
            <Paper elevation={0} variant="outlined">
              <Box sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Industry Reports
                </Typography>
                <List>
                  <SettingItem
                    label="Show CBRE Reports"
                    checked={localSettings.showCBREReports}
                    onChange={handleSwitchChange('showCBREReports')}
                  />
                  <SettingItem
                    label="Show other industry reports"
                    checked={localSettings.showIndustryReports}
                    onChange={handleSwitchChange('showIndustryReports')}
                  />
                </List>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Settings;
