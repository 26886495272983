import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { collection, query, where, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import {
  Box,
  Paper,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Chip,
  Tabs,
  Tab,
  Divider,
  TextField,
  Stack,
  Autocomplete,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  CalendarMonth as CalendarIcon,
  ViewList as ListView,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const localizer = momentLocalizer(moment);

// Column definitions
const columns = [
  { 
    id: 'propertyName', 
    label: 'Property Name', 
    minWidth: 170 
  },
  { 
    id: 'amount', 
    label: 'Rent per Month', 
    minWidth: 130,
    format: (value) => `₹${value.toLocaleString('en-IN')}`
  },
  { 
    id: 'dueDate', 
    label: 'Due Date', 
    minWidth: 130,
    format: (value) => value ? moment(value.toDate()).format('DD/MM/YYYY') : '-'
  },
  { 
    id: 'status', 
    label: 'Status', 
    minWidth: 100,
    format: (value) => (
      <Chip 
        label={value} 
        color={value === 'pending' ? 'warning' : value === 'paid' ? 'success' : 'error'} 
        size="small"
      />
    )
  },
  { 
    id: 'type', 
    label: 'Payment Type', 
    minWidth: 100,
    format: () => 'Rent'
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 50,
    align: 'right',
  }
];

function Payments() {
  const [payments, setPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('dueDate'); 
  const [order, setOrder] = useState('asc');         
  const [view, setView] = useState('list');
  const [propertyFilter, setPropertyFilter] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  
  const auth = useSelector((state) => state.auth);
  const organizationId = auth?.organizationId;
  const { properties, loading: propertiesLoading } = useSelector((state) => state.property);
  
  console.log('Auth state:', auth);
  console.log('Organization ID:', organizationId);
  console.log('Properties from Redux:', properties);
  
  const propertyOptions = useMemo(() => 
    properties.map(property => ({
      id: property.id,
      label: property.propertyName
    })), [properties]
  );

  const handleActionClick = (event, payment) => {
    setAnchorEl(event.currentTarget);
    setSelectedPayment(payment);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedPayment(null);
  };

  const handleMarkAsPaid = async () => {
    if (!selectedPayment) return;

    try {
      // Get the property document reference
      const propertyRef = doc(db, 'properties', selectedPayment.propertyId);
      
      // Update the payment status in the payments subcollection
      const paymentRef = doc(propertyRef, 'payments', selectedPayment.id);
      await updateDoc(paymentRef, {
        status: 'paid',
        paidDate: new Date()
      });

      setSnackbar({
        open: true,
        message: 'Payment marked as paid successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error marking payment as paid:', error);
      setSnackbar({
        open: true,
        message: 'Error marking payment as paid',
        severity: 'error'
      });
    }
    handleCloseMenu();
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  // Subscribe to payments when properties change
  useEffect(() => {
    console.log('Payments subscription effect running');
    if (!organizationId || !properties.length) {
      console.log('Missing organizationId or no properties:', { organizationId, propertiesCount: properties.length });
      return;
    }

    const paymentSubscriptions = [];
    
    // Subscribe to payments collection for each property
    properties.forEach(property => {
      console.log('Setting up payment subscription for property:', property.id);
      const paymentsQuery = collection(db, 'properties', property.id, 'payments');
      
      const paymentSub = onSnapshot(paymentsQuery, (paymentSnapshot) => {
        console.log(`Received payments for property ${property.id}:`, paymentSnapshot.size);
        const paymentDocs = paymentSnapshot.docs.map(paymentDoc => ({
          id: paymentDoc.id,
          propertyId: property.id,
          propertyName: property.propertyName,
          ...paymentDoc.data()
        }));
        
        setPayments(currentPayments => {
          const otherPayments = currentPayments.filter(p => p.propertyId !== property.id);
          const newPayments = [...otherPayments, ...paymentDocs];
          const sortedPayments = newPayments.sort((a, b) => 
            moment(a.dueDate.toDate()).valueOf() - moment(b.dueDate.toDate()).valueOf()
          );
          // console.log('Updated payments state:', sortedPayments.length);
          return sortedPayments;
        });
      }, (error) => {
        console.error('Error in payment subscription:', error);
      });
      
      paymentSubscriptions.push(paymentSub);
    });
    
    return () => {
      console.log('Cleaning up payment subscriptions');
      paymentSubscriptions.forEach(unsub => unsub());
    };
  }, [organizationId, properties]);

  // Initialize filteredPayments with all payments
  useEffect(() => {
    console.log('Setting filtered payments:', payments.length);
    setFilteredPayments(payments);
  }, [payments]);

  // Filter payments whenever filters change
  useEffect(() => {
    console.log('Filter effect running with:', { propertyFilter, startDate, endDate });
    let filtered = [...payments];
    
    if (propertyFilter) {
      filtered = filtered.filter(payment => {
        return payment.propertyName.toLowerCase() === propertyFilter.label.toLowerCase();
      });
    }

    if (startDate) {
      filtered = filtered.filter(payment => 
        moment(payment.dueDate.toDate()).isSameOrAfter(startDate, 'day')
      );
    }

    if (endDate) {
      filtered = filtered.filter(payment => 
        moment(payment.dueDate.toDate()).isSameOrBefore(endDate, 'day')
      );
    }

    console.log('Filtered payments:', filtered.length);
    setFilteredPayments(filtered);
    // Reset to first page when filters change
    setPage(0);
  }, [payments, propertyFilter, startDate, endDate]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleViewChange = (event, newValue) => {
    setView(newValue);
  };

  // Sort function
  const sortPayments = (a, b) => {
    if (orderBy === 'dueDate') {
      return order === 'asc' 
        ? moment(a.dueDate.toDate()).valueOf() - moment(b.dueDate.toDate()).valueOf()
        : moment(b.dueDate.toDate()).valueOf() - moment(a.dueDate.toDate()).valueOf();
    }
    
    if (orderBy === 'amount') {
      return order === 'asc' 
        ? a.amount - b.amount
        : b.amount - a.amount;
    }
    
    return order === 'asc'
      ? (a[orderBy] || '').localeCompare(b[orderBy] || '')
      : (b[orderBy] || '').localeCompare(a[orderBy] || '');
  };

  const sortedPayments = filteredPayments.sort(sortPayments);

  // Convert payment data to calendar events
  const calendarEvents = sortedPayments.map(payment => ({
    title: `${payment.propertyName} - ₹${payment.amount.toLocaleString('en-IN')}`,
    start: payment.dueDate.toDate(),
    end: payment.dueDate.toDate(),
    resource: payment,
  }));

  return (
    <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {/* Filters */}
      <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Autocomplete
            value={propertyFilter}
            onChange={(event, newValue) => setPropertyFilter(newValue)}
            options={propertyOptions}
            renderInput={(params) => (
              <TextField {...params} label="Filter by Property" size="small" sx={{ width: 300 }} />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={setStartDate}
              slotProps={{ textField: { size: 'small' } }}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={setEndDate}
              slotProps={{ textField: { size: 'small' } }}
            />
          </LocalizationProvider>
        </Stack>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={view} 
          onChange={handleViewChange}
          sx={{ 
            minHeight: 48,
            '& .MuiTab-root': {
              minHeight: 48,
              textTransform: 'none',
            }
          }}
        >
          <Tab 
            icon={<ListView />} 
            iconPosition="start" 
            label="List View" 
            value="list"
          />
          <Tab 
            icon={<CalendarIcon />} 
            iconPosition="start" 
            label="Calendar View" 
            value="calendar"
          />
        </Tabs>
      </Box>
      <Divider />

      {view === 'list' ? (
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={() => handleRequestSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedPayments
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((payment) => (
                    <TableRow hover key={payment.id}>
                      {columns.map((column) => {
                        const value = payment[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === 'actions' ? (
                              <IconButton
                                size="small"
                                onClick={(e) => handleActionClick(e, payment)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            ) : column.format ? (
                              column.format(value)
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={sortedPayments.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      ) : (
        <Box sx={{ flex: 1, p: 2 }}>
          <Calendar
            localizer={localizer}
            events={calendarEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: '100%' }}
            views={['month', 'week', 'day']}
          />
        </Box>
      )}
      {/* Action Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {selectedPayment?.status === 'pending' && (
          <MenuItem onClick={handleMarkAsPaid}>Mark as Paid</MenuItem>
        )}
      </Menu>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

export default Payments;
