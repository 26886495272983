import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Paper,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Stack,
  Autocomplete,
  TextField,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Alert,
  Snackbar,
  Divider
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { db } from '../firebase';
import { collection, query, where, getDocs, addDoc, Timestamp, doc, updateDoc, deleteDoc } from 'firebase/firestore';

const REQUEST_TYPES = [
  'House Keeping',
  'Utility',
  'Maintenance',
  'Security',
  'Pest Control',
  'Others'
];

const REQUEST_STATUS = [
  'Open',
  'In Progress',
  'Closed',
  'Cancelled'
];

function ServiceRequests() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [propertyFilter, setPropertyFilter] = useState(null);
  const [cityFilter, setCityFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [requests, setRequests] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editingRequest, setEditingRequest] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [requestToDelete, setRequestToDelete] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const { organizationId } = useSelector((state) => state.auth);
  const { properties } = useSelector((state) => state.property);

  const cities = [...new Set(properties.map(property => property.city))];

  const [formData, setFormData] = useState({
    requestName: '',
    requestType: '',
    reportedOn: moment(),
    reportedBy: '',
    dueDate: moment(),
    status: 'Open',
    description: '',
    propertyId: '',
  });

  useEffect(() => {
    if (organizationId) {
      fetchRequests();
    }
  }, [organizationId]);

  const fetchRequests = async () => {
    try {
      const allRequests = [];
      
      // Fetch requests from each property's serviceRequest subcollection
      for (const property of properties) {
        const requestsRef = collection(db, 'properties', property.id, 'serviceRequests');
        const q = query(requestsRef);
        const querySnapshot = await getDocs(q);
        
        const propertyRequests = querySnapshot.docs.map(doc => ({
          id: doc.id,
          propertyId: property.id,
          ...doc.data(),
          reportedOn: moment(doc.data().reportedOn.toDate()),
          dueDate: moment(doc.data().dueDate.toDate()),
        }));
        
        allRequests.push(...propertyRequests);
      }
      
      setRequests(allRequests);
    } catch (error) {
      console.error('Error fetching requests:', error);
      showSnackbar('Error fetching requests', 'error');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const requestData = {
        ...formData,
        organizationId,
        reportedOn: Timestamp.fromDate(formData.reportedOn.toDate()),
        dueDate: Timestamp.fromDate(formData.dueDate.toDate()),
        createdAt: Timestamp.now(),
      };

      if (editingRequest) {
        // Update existing request in property's serviceRequest collection
        const requestRef = doc(db, 'properties', formData.propertyId, 'serviceRequests', editingRequest.id);
        await updateDoc(requestRef, requestData);
        showSnackbar('Request updated successfully');
      } else {
        // Add new request to property's serviceRequest collection
        const requestRef = collection(db, 'properties', formData.propertyId, 'serviceRequests');
        await addDoc(requestRef, requestData);
        showSnackbar('Request added successfully');
      }

      handleCloseModal();
      fetchRequests();
    } catch (error) {
      console.error('Error saving request:', error);
      showSnackbar('Error saving request', 'error');
    }
  };

  const handleDelete = async (request) => {
    setRequestToDelete(request);
    setDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      // Delete request from property's serviceRequest collection
      const requestRef = doc(db, 'properties', requestToDelete.propertyId, 'serviceRequests', requestToDelete.id);
      await deleteDoc(requestRef);
      showSnackbar('Request deleted successfully');
      fetchRequests();
    } catch (error) {
      console.error('Error deleting request:', error);
      showSnackbar('Error deleting request', 'error');
    } finally {
      setDeleteConfirmOpen(false);
      setRequestToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setDeleteConfirmOpen(false);
    setRequestToDelete(null);
  };

  const handleEdit = (request) => {
    setEditingRequest(request);
    setFormData({
      requestName: request.requestName,
      requestType: request.requestType,
      reportedOn: moment(request.reportedOn),
      reportedBy: request.reportedBy,
      dueDate: moment(request.dueDate),
      status: request.status,
      description: request.description,
      propertyId: request.propertyId,
    });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditingRequest(null);
    setFormData({
      requestName: '',
      requestType: '',
      reportedOn: moment(),
      reportedBy: '',
      dueDate: moment(),
      status: 'Open',
      description: '',
      propertyId: '',
    });
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const filteredRequests = requests.filter(request => {
    const property = properties.find(p => p.id === request.propertyId);
    const matchesProperty = !propertyFilter || request.propertyId === propertyFilter;
    const matchesCity = !cityFilter || (property && property.city === cityFilter);
    const matchesStatus = !statusFilter || request.status === statusFilter;
    return matchesProperty && matchesCity && matchesStatus;
  });

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center">
            <Autocomplete
              options={properties}
              getOptionLabel={(option) => option.propertyName}
              value={properties.find(p => p.id === propertyFilter) || null}
              onChange={(_, newValue) => setPropertyFilter(newValue?.id || null)}
              renderInput={(params) => (
                <TextField {...params} label="Property" size="small" />
              )}
              sx={{ minWidth: 200 }}
            />
            <Autocomplete
              options={cities}
              value={cityFilter}
              onChange={(_, newValue) => setCityFilter(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="City" size="small" />
              )}
              sx={{ minWidth: 200 }}
            />
            <FormControl size="small" sx={{ minWidth: 200 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={statusFilter || ''}
                label="Status"
                onChange={(e) => setStatusFilter(e.target.value || null)}
              >
                <MenuItem value="">All</MenuItem>
                {REQUEST_STATUS.map((status) => (
                  <MenuItem key={status} value={status}>{status}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ 
                height: 40,
                borderRadius: 2,
                textTransform: 'none',
                px: 3,
                bgcolor: 'primary.dark',
                '&:hover': {
                  bgcolor: 'primary.main',
                }
              }}
              onClick={() => setOpenModal(true)}
            >
              Add Request
            </Button>
          </Stack>
        </Box>

        <TableContainer>
        <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>Request Name</TableCell>
                <TableCell>Property</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Reported On</TableCell>
                <TableCell>Due Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRequests
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((request) => {
                  const property = properties.find(p => p.id === request.propertyId);
                  return (
                    <TableRow key={request.id}>
                      <TableCell>{request.requestName}</TableCell>
                      <TableCell>{property?.propertyName}</TableCell>
                      <TableCell>{request.requestType}</TableCell>
                      <TableCell>{moment(request.reportedOn).format('DD MMM YYYY')}</TableCell>
                      <TableCell>{moment(request.dueDate).format('DD MMM YYYY')}</TableCell>
                      <TableCell>
                        <Chip
                          label={request.status}
                          color={
                            request.status === 'Open' ? 'error' :
                              request.status === 'In Progress' ? 'warning' :
                                request.status === 'Closed' ? 'success' :
                                  'default'
                          }
                          size="small"
                        />
                      </TableCell>
                      <TableCell align="right">
                        <IconButton size="small" onClick={() => handleEdit(request)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton size="small" onClick={() => handleDelete(request)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredRequests.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(parseInt(e.target.value, 10));
              setPage(0);
            }}
          />
        </TableContainer>

        {/* Add/Edit Request Dialog */}
        <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
          <DialogTitle>
            {editingRequest ? 'Edit Service Request' : 'New Service Request'}
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Stack spacing={2}>
                <TextField
                  label="Request Name"
                  value={formData.requestName}
                  onChange={(e) => setFormData({ ...formData, requestName: e.target.value })}
                  required
                  fullWidth
                />
                <FormControl fullWidth required>
                  <InputLabel>Property</InputLabel>
                  <Select
                    value={formData.propertyId}
                    label="Property"
                    onChange={(e) => setFormData({ ...formData, propertyId: e.target.value })}
                  >
                    {properties.map((property) => (
                      <MenuItem key={property.id} value={property.id}>
                        {property.propertyName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth required>
                  <InputLabel>Request Type</InputLabel>
                  <Select
                    value={formData.requestType}
                    label="Request Type"
                    onChange={(e) => setFormData({ ...formData, requestType: e.target.value })}
                  >
                    {REQUEST_TYPES.map((type) => (
                      <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <DatePicker
                  label="Reported On"
                  value={formData.reportedOn}
                  onChange={(date) => setFormData({ ...formData, reportedOn: date })}
                  renderInput={(params) => <TextField {...params} fullWidth required />}
                />
                <TextField
                  label="Reported By"
                  value={formData.reportedBy}
                  onChange={(e) => setFormData({ ...formData, reportedBy: e.target.value })}
                  required
                  fullWidth
                />
                <DatePicker
                  label="Due Date"
                  value={formData.dueDate}
                  onChange={(date) => setFormData({ ...formData, dueDate: date })}
                  renderInput={(params) => <TextField {...params} fullWidth required />}
                />
                <FormControl fullWidth required>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={formData.status}
                    label="Status"
                    onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                  >
                    {REQUEST_STATUS.map((status) => (
                      <MenuItem key={status} value={status}>{status}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="Description"
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  multiline
                  rows={4}
                  fullWidth
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal}>Cancel</Button>
              <Button type="submit" variant="contained">
                {editingRequest ? 'Update' : 'Add'} Request
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={deleteConfirmOpen}
          onClose={handleCancelDelete}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
        >
          <DialogTitle id="delete-dialog-title">
            Confirm Delete
          </DialogTitle>
          <DialogContent>
            <Typography id="delete-dialog-description">
              Are you sure you want to delete the service request "{requestToDelete?.requestName}"? This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="error" variant="contained" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Paper>
    </LocalizationProvider>
  );
}

export default ServiceRequests;
