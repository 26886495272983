import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { Close as CloseIcon } from '@mui/icons-material';
import moment from 'moment';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';

const MeetingScheduler = ({ 
  open, 
  onClose, 
  title = 'Schedule Meeting',
  description,
  meetingType,
  referenceId,
  onScheduled 
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(30);

  // Generate time slots for the selected date (9 AM to 6 PM, 30-min intervals)
  const generateTimeSlots = (date) => {
    if (!date) return [];
    
    const slots = [];
    let currentTime = moment(date).hour(9).minute(0); // Start at 9 AM
    const endTime = moment(date).hour(18).minute(0); // End at 6 PM

    while (currentTime.isBefore(endTime)) {
      slots.push({
        time: currentTime.toDate(),
        available: Math.random() > 0.3, // Simulate availability (70% available)
      });
      currentTime = moment(currentTime).add(30, 'minutes');
    }
    return slots;
  };

  const timeSlots = selectedDate ? generateTimeSlots(selectedDate) : [];

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTimeSlot(null);
  };

  const handleTimeSlotSelect = (slot) => {
    if (slot.available) {
      setSelectedTimeSlot(slot.time);
    }
  };

  const handleScheduleSubmit = async () => {
    if (!selectedDate || !selectedTimeSlot) return;

    try {
      const meeting = await addDoc(collection(db, 'meetings'), {
        title,
        description,
        startTime: selectedTimeSlot,
        duration: selectedDuration,
        type: meetingType,
        referenceId,
        status: 'scheduled',
        createdAt: new Date(),
      });

      if (onScheduled) {
        onScheduled(meeting);
      }
      
      handleClose();
    } catch (error) {
      console.error('Error scheduling meeting:', error);
    }
  };

  const handleClose = () => {
    setSelectedDate(null);
    setSelectedTimeSlot(null);
    setSelectedDuration(30);
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{title}</Typography>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              1. Select a Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <StaticDatePicker
                value={selectedDate}
                onChange={handleDateChange}
                minDate={moment()}
                maxDate={moment().add(30, 'days')}
                disableWeekends
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              2. Select a Time ({selectedDuration} minutes)
            </Typography>
            <Box sx={{ 
              maxHeight: 400, 
              overflowY: 'auto',
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: 1,
              p: 1
            }}>
              {timeSlots.map((slot, index) => (
                <Button
                  key={index}
                  variant={selectedTimeSlot?.getTime() === slot.time.getTime() ? "contained" : "outlined"}
                  color={slot.available ? "primary" : "error"}
                  disabled={!slot.available}
                  onClick={() => handleTimeSlotSelect(slot)}
                  sx={{
                    py: 1,
                    justifyContent: 'center',
                    opacity: slot.available ? 1 : 0.5
                  }}
                >
                  {moment(slot.time).format('h:mm A')}
                </Button>
              ))}
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Duration
              </Typography>
              <ToggleButtonGroup
                value={selectedDuration}
                exclusive
                onChange={(e, value) => value && setSelectedDuration(value)}
                size="small"
                sx={{ width: '100%' }}
              >
                <ToggleButton value={15} sx={{ flexGrow: 1 }}>
                  15 min
                </ToggleButton>
                <ToggleButton value={30} sx={{ flexGrow: 1 }}>
                  30 min
                </ToggleButton>
                <ToggleButton value={45} sx={{ flexGrow: 1 }}>
                  45 min
                </ToggleButton>
                <ToggleButton value={60} sx={{ flexGrow: 1 }}>
                  60 min
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            {selectedTimeSlot && (
              <Alert severity="info" sx={{ mt: 2 }}>
                Selected time: {moment(selectedTimeSlot).format('LLLL')}
                <br />
                Duration: {selectedDuration} minutes
              </Alert>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleScheduleSubmit}
          disabled={!selectedDate || !selectedTimeSlot}
        >
          Schedule Meeting
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MeetingScheduler;
