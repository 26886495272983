import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControlLabel,
  Checkbox,
  LinearProgress,
  Chip,
  Button,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Warning as WarningIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import { collection, doc, setDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { 
  COMPLIANCE_TYPES, 
  COMPLIANCE_CHECKLIST, 
  COMPLIANCE_WEIGHTS 
} from '../constants/compliance';

const ComplianceChecklist = ({ propertyId, type, items, existingCompliance, onUpdate }) => {
  const [checkedItems, setCheckedItems] = useState({});

  useEffect(() => {
    if (existingCompliance && existingCompliance[type]) {
      setCheckedItems(existingCompliance[type]);
    }
  }, [existingCompliance, type]);

  const handleChange = async (itemId) => {
    const newCheckedItems = {
      ...checkedItems,
      [itemId]: !checkedItems[itemId]
    };
    setCheckedItems(newCheckedItems);
    onUpdate(type, newCheckedItems);
  };

  return (
    <FormGroup>
      {items.map((item) => (
        <FormControlLabel
          key={item.id}
          control={
            <Checkbox
              checked={checkedItems[item.id] || false}
              onChange={() => handleChange(item.id)}
            />
          }
          label={
            <Box>
              <Typography variant="subtitle1">{item.label}</Typography>
              <Typography variant="body2" color="text.secondary">
                {item.description}
              </Typography>
            </Box>
          }
        />
      ))}
    </FormGroup>
  );
};

const PropertyComplianceDialog = ({ open, onClose, property, compliance, onUpdate }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            {property.propertyName} - Compliance Checklist
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {Object.entries(COMPLIANCE_TYPES).map(([key, type]) => (
          <Accordion key={key}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
                <Typography>{type}</Typography>
                {compliance[type] && (
                  <Chip
                    size="small"
                    label={`${
                      Object.values(compliance[type]).filter(Boolean).length
                    }/${COMPLIANCE_CHECKLIST[type].length} Complete`}
                    color={
                      Object.values(compliance[type]).filter(Boolean).length ===
                      COMPLIANCE_CHECKLIST[type].length
                        ? 'success'
                        : 'default'
                    }
                  />
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <ComplianceChecklist
                propertyId={property.id}
                type={type}
                items={COMPLIANCE_CHECKLIST[type]}
                existingCompliance={compliance}
                onUpdate={onUpdate}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
    </Dialog>
  );
};

const isCompliant = (compliance, type) => {
  if (!compliance || !compliance[type]) return false;
  const checklist = COMPLIANCE_CHECKLIST[type];
  const completedItems = Object.values(compliance[type]).filter(Boolean).length;
  return completedItems === checklist.length;
};

const calculateComplianceScore = (compliance) => {
  if (!compliance) return 0;

  let score = 0;
  Object.values(COMPLIANCE_TYPES).forEach((type) => {
    if (isCompliant(compliance, type)) {
      score += 1;
    }
  });

  return score;
};

const Compliance = () => {
  const [complianceData, setComplianceData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { properties } = useSelector((state) => state.property);

  useEffect(() => {
    if (!properties) return;

    const unsubscribers = properties.map(property => {
      const complianceRef = collection(db, 'properties', property.id, 'compliance');
      
      return onSnapshot(doc(complianceRef, 'status'), (doc) => {
        setComplianceData(prev => ({
          ...prev,
          [property.id]: doc.exists() ? doc.data() : {}
        }));
        setLoading(false);
      }, (err) => {
        console.error('Error fetching compliance:', err);
        setError('Failed to fetch compliance data');
        setLoading(false);
      });
    });

    return () => unsubscribers.forEach(unsubscribe => unsubscribe());
  }, [properties]);

  const handleComplianceUpdate = async (propertyId, type, checkedItems) => {
    try {
      const complianceRef = doc(db, 'properties', propertyId, 'compliance', 'status');
      await setDoc(complianceRef, {
        ...complianceData[propertyId],
        [type]: checkedItems,
        lastUpdated: new Date()
      }, { merge: true });
    } catch (err) {
      console.error('Error updating compliance:', err);
      setError('Failed to update compliance status');
    }
  };

  const filteredProperties = properties?.filter(property => 
    property.propertyName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          placeholder="Search by property name..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Property</TableCell>
              <TableCell align="center">Score</TableCell>
              {Object.values(COMPLIANCE_TYPES).map((type) => (
                <TableCell key={type} align="center">{type}</TableCell>
              ))}
              <TableCell align="center">Checklist</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProperties?.map((property) => {
              const propertyCompliance = complianceData[property.id] || {};
              const score = calculateComplianceScore(propertyCompliance);

              return (
                <TableRow key={property.id}>
                  <TableCell component="th" scope="row">
                    {property.propertyName}
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      size="small"
                      label={`${score}/10`}
                      color={score >= 8 ? 'success' : 'warning'}
                    />
                  </TableCell>
                  {Object.values(COMPLIANCE_TYPES).map((type) => (
                    <TableCell key={type} align="center">
                      {isCompliant(propertyCompliance, type) ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <CancelIcon color="error" />
                      )}
                    </TableCell>
                  ))}
                  
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => setSelectedProperty(property)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedProperty && (
        <PropertyComplianceDialog
          open={Boolean(selectedProperty)}
          onClose={() => setSelectedProperty(null)}
          property={selectedProperty}
          compliance={complianceData[selectedProperty.id] || {}}
          onUpdate={(type, items) => handleComplianceUpdate(selectedProperty.id, type, items)}
        />
      )}
    </Paper>
  );
};

export default Compliance;
